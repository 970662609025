import {
  areActivityDatesInvalid,
  isActivityLimitReached
} from '@Utils/activity/activity';
import {getVariantById} from '@Utils/activity/variant';
import {getActivityBasketItems} from '@Utils/basket';
import locale from '@Utils/locale';
import {GetInvalidFieldMessage, GetPickerDisableReason, GetVariantGroupMinLimit, GetVariantMinLimit} from './types';

export const getPickerDisableReason = ({
  activity,
  basketItems,
  maximumTicketsInOrder,
  variantId
}: GetPickerDisableReason) => {
  const datesAreInvalid = areActivityDatesInvalid(activity);
  const {id: activityId, variants} = activity;
  const variant = getVariantById(variants, variantId);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const numberOfBasketItems = basketItems.length;

  if (datesAreInvalid) {
    return locale.translate('noValidDatesRange');
  }

  if (numberOfBasketItems >= maximumTicketsInOrder) {
    return locale.translate('chooseLimitReached');
  }

  if (variant) {
    const selectedActivityBasketItemAmount = activityBasketItems
      .reduce(amount => amount + 1, 0);

    if (isActivityLimitReached(activity, selectedActivityBasketItemAmount)) {
      return locale.translate('canNotBuyMoreTickets');
    }
  }

  return null;
};

export const getVariantGroupLimit = ({
  variant,
  activity
}: GetVariantGroupMinLimit) => {
  if (!variant) {
    return 0;
  }

  const maxNumberOfSeats = variant.maxNumberOfSeats;
  const maxNumberOfParticipants = activity.admissionPolicy.maxNumberOfParticipants;

  return maxNumberOfSeats || maxNumberOfParticipants || 0;
};

export const getVariantMinLimit = ({
  variant,
  activity,
  maximumTicketsInOrder,
  numberOfBasketItems,
  numberOfVariantItems
}: GetVariantMinLimit) => {
  if (!variant) {
    return 0;
  }

  const {limit, usesCount} = activity?.salePolicy;
  const orderLimit = maximumTicketsInOrder - numberOfBasketItems + numberOfVariantItems;
  const usesCountLimit = limit ? limit - usesCount : null;
  const existsLimits = [
    orderLimit, usesCountLimit
  ].filter(v => Number.isInteger(v)) as number[];

  return existsLimits.length ? Math.min(...existsLimits) : 0;
};

export const getInvalidFieldMessage = (
  {maxValue, minValue, inputValue}: GetInvalidFieldMessage
) => {
  if (maxValue === 0 && (inputValue || inputValue === '')) {
    return locale.translate('maxValueError', {value: maxValue});
  }

  if (inputValue !== 0 && inputValue < minValue) {
    return locale.translate('minValueError', {value: minValue});
  }

  if (inputValue > maxValue) {
    return locale.translate('maxValueError', {value: maxValue});
  }

  return null;
};
