/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'Вибраний діапазон містить недоступні терміни',
  'PLN': 'зл',
  'EUR': 'євро',
  'PLNtestB': 'zł',
  'EURtestB': '€',
  'validPostalCodeLength': 'Введіть дійсний поштовий індекс (наприклад, 00-000)',
  'minLength': 'Мінімальна кількість символів: %{length}',
  'maxLength': 'Максимальна кількість символів: %{length}',
  'parcelLockerSelected': 'Вибраний пачкомат:',
  'selectParcelLocker': 'Виберіть пачкомат',
  'selectAnotherParcelLocker': 'Виберіть інший пачкомат',
  'parcelLockerPointError': 'Не вдалося отримати правильну адресу пачкомату',
  'enterDeliveryPhoneNumber': 'Вкажіть номер телефону для доставки',
  'onlineMethodsDescription': 'Для інших послуг електронна доставка.',
  'shippingMethodRequired': 'Перш ніж перейти до оплати, необхідно вибрати спосіб доставки.',
  'chooseShippingMethod': 'Виберіть спосіб доставки',
  'generateWithAmount': 'Генеруйте (%{amountToPay})',
  'additionsHeader': 'Додатки: %{activityName}',
  'additionsLimitReached': 'Досягнуто ліміт додатків',
  'perPersonNumber': '%{number} осіб x',
  'selectedDayIsNotAvailable': 'День %{date} недоступний.',
  'selectedDateIsNotAvailable': 'Дата %{date} недоступна',
  'errorOccurredWhileFetchingOrder': 'Не вдалося завантажити замовлення. Якщо Ви заплатили за нього, перевірте свою електронну пошту.',
  'priceTooHigh': 'Вартість обраних послуг занадто висока. Неможливо перейти до оплати.',
  'invalidNip': 'Неправильно вказано номер ПДВ',
  'orderCreationError': 'Під час створення замовлення сталася помилка. Спробуйте ще раз.',
  'activitiesFetchError': 'Під час завантаження сталася помилка',
  'chooseLimitReached': 'Досягнуто ліміту вибраних творів',
  'localTours': 'Місцеві екскурсії',
  'clauseAcceptanceRequired': 'Необхідно прийняти положення',
  'invalidPostalCode': 'Неправильний поштовий індекс',
  'invalidNIPLength': 'NIP має складатися з 10 цифр',
  'invalidPhone': 'Неправильний номер телефону',
  'invalidEmail': 'Неправильна адреса електронної пошти',
  'invalidFormatError': 'Неправильний формат',
  'toLongError': 'Максимальна довжина – 30 символів',
  'toShortError': 'Мінімальна довжина – 3 символи',
  'invalidTypeError': 'Неправильна вартість',
  'missingFieldError': 'Вміст цього поля не було отримано',
  'unknownError': 'Невідома помилка',
  'errorScriptNotFound': 'Помилка - Ваш браузер не підтримується або інтернет-магазин встановлено неправильно',
  'addressRequired': 'Необхідно подати адрес',
  'regulationAcceptRequired': 'Необхідно прийняти Регламент та Політику конфіденційності',
  'regulationsAcceptRequired': 'Необхідно прийняти Регламент та Політику конфіденційності',
  'phoneRequired': 'Необхідно вказати номер телефону',
  'lastNameRequired': 'Необхідно вказати прізвище',
  'firstNameRequired': 'Необхідно вказати ім’я',
  'emailRequired': 'Необхідна адреса електронної пошти',
  'answerRequired': 'Необхідна відповідь',
  'skippTheLine': 'Пропустіть чергу до каси',
  'downloadInPreviewIsDisabled': 'У режимі попереднього перегляду замовлення надсилається на електронну пошту, його неможливо завантажити безпосередньо з цього екрана',
  'noValidDatesRange': 'Немає доступного діапазону дат',
  'chooseNumberOfDays': 'Виберіть кількість днів',
  'day': 'день',
  'perPersonAbbr': 'ос.',
  'maxNumberOfParticipants': 'Максимальна кількість учасників – %{number}',
  'minNumberOfParticipants': 'Мінімальна кількість учасників – %{number}',
  'enterNumberOfParticipants': 'Введіть кількість учасників',
  'numberOfParticipants': 'Кількість учасників',
  'numberOfParticipantsBetween': 'Кількість учасників: від %{min} до %{max}',
  'generate': 'Генеруйте',
  'seats': 'шт.',
  'attention': 'Увага!',
  'forSinglePerson': 'за 1 особу',
  'chooseAdditions': 'Виберіть додатки',
  'pleaseChooseOneOption': 'Необхідно вибрати один із доступних варіантів',
  'or': 'або',
  'addToAppleWallet': 'Додати до Apple Wallet',
  'realiseOrder': 'Замовте',
  'generatingPdf': 'Генерація PDF...',
  'transactionCompletedSuccessfully': 'Транзакція успішно завершена!',
  'immediatelyProceedThroughPayment': 'Після отримання підтвердження оплати квитки будуть завантажені нижче та відправлені на адресу %p',
  'optionally': 'необов\'язкове',
  'shopOnline': 'Інтернет-магазин',
  'orderOnline': 'Замовляйте онлайн',
  'discountsAreDisabledInPreview': 'У режимі попереднього перегляду коди неактивні',
  'contactNumberToClient': 'Контактний номер особи, яка буде користуватись послугою - збирається лише для цілей її реалізації, наприклад, для зв’язку у надзвичайній ситуації.',
  'numberOnlyForRealisation': 'Номер лише з метою надання послуги',
  'unsuccessfullyOrderedSending': 'Неможливо надіслати підтвердження замовлення на %{email}. Будь ласка, спробуйте ще раз або напишіть на pomoc@droplabs.pl',
  'successfullyOrderedSending': 'Відправку за адресою %{email} було виконано правильно',
  'requiredField': 'Обов\'язкове поле',
  'forSendingAcceptTos': 'Для потреби доставки, клієнт погоджується',
  'agreementFormWithClause': 'Форма згоди з Інформаційним пунктом',
  'send': 'Відправити',
  'downloadToPrint': 'Скачати для друку',
  'clientEmail': 'Адреса електронної пошти клієнта',
  'chooseSendingConfirmationMethod': 'Готовий! Виберіть, як переслати підтвердження клієнту',
  'orderPreviewInfo': 'Зразок замовлення надіслано на електронну адресу: %р. Якщо воно не прийде через кілька хвилин, перевірте спам або знайдіть слово \\Droplabs\\ в своїй поштовій програмі.',
  'exceededTimeout': 'Час очікування на платіж перевищено',
  'buyerWillSettleWithYou': 'Особа, яка буде користуватися послугами %p, розраховується з Вами за них.',
  'youCanPrintTickets': 'Після покупки Ви можете роздрукувати готові квитки. Ціни на квитках не буде.',
  'onlinePaymentForOrder': 'Ви оплачуєте замовлення онлайн. Рахунок-фактура виставлятиметься %p для Вашої компанії за фіксований період, зазвичай разом за весь місяць.',
  'youWillPayForOrderBasedOnInvoice': 'Ви оплачуєте замовлення на підставі рахунка-фактури, яку буде виставлено %p для Вашої компанії за фіксований період, зазвичай разом за весь місяць.',
  'wholeAmountWillBeChargedForUSed': 'Повну суму буде стягнуто %p лише за використані квитки. %p може стягувати додаткову комісію, також за невикористані квитки.',
  'youCanPrintReservationConfirmation': 'Після бронювання Ви можете відразу роздрукувати його підтвердження.',
  'paymentWillBeChargedOnSite': 'Повну суму замовлення необхідно буде оплатити на місці при наданні послуги.',
  'missingFacilityId': 'Інтернет-магазин розміщено неправильно',
  'cannotLoadFacility': 'Не вдалося завантажити магазин. Спробуйте пізніше.',
  'add': 'Додати',
  'choose': 'Вибрати',
  'choosing': 'Вибір',
  'hide': 'Згорнути',
  'expand': 'Розгорнути',
  'menuDetails': 'Деталі',
  'menuSummary': 'Оплатити',
  'menuSummaryThanks': 'Дякуємо за замовлення!',
  'noActivitiesInSales': 'Немає послуг у продажу. Запрошуємо незабаром!',
  'hours': 'год',
  'minutes': 'хвилин',
  'goToTheNextStep': 'Давай',
  'sellerOfActivities': 'Продавець – %{name}.',
  'ifYouNeedHelpAssociatedWithOffer': 'У Вас є запитання щодо пропозиції? Пишіть на %p.',
  'saleSupportedByDroplabs': 'Droplabs - Інтернет-магазин, система продажу та розповсюдження послуг, квитків, ваучерів і абонементів.',
  'ifYouNeedTechnicalSupport': 'Потрібна технічна підтримка? Напишіть до %p',
  'activityAdmissionDateLabel': 'Дата використання: %{name}',
  'chooseDayOfUseActivities': 'Виберіть день виконання послуги',
  'chooseTimeOfUseActivities': 'Виберіть час виконання послуги',
  'chooseNarrowedTimeOfUseActivities': 'Виберіть обмеження за часом для виконання послуги',
  'chooseTime': 'Виберіть час',
  'chooseEmailForSendingTickets': 'На яку адресу надіслати підтвердження покупки?',
  'email': 'Адреса електронної пошти',
  'name': 'Ім\'я',
  'buyersName': 'Ім\'я покупця',
  'surname': 'Прізвище',
  'buyersSurname': 'Прізвище покупця',
  'goToThePayment': 'Перейдіть до оплати',
  'orderFreeTickets': 'Замовляю',
  'buyWithoutPayment': 'Купую із зобов’язанням оплати',
  'businessTicketsHaveBeenSentIntoEmail': 'Квиток надіслано на адресу: %p |||| Квитки надіслано на адресу: %p',
  'checkSpamInCaseOfNotReceivingEmail': 'Якщо ви не бачите жодного електронного листа від нас протягом кількох хвилин, перевірте папку зі спамом або введіть Droplabs у пошуковому рядку поштової скриньки.',
  'buyAnotherActivities': 'Замовте більше послуг',
  'noFixedDatesForChosenActivities': 'На жаль, для вибраних квитків немає доступних дат.',
  'forChosen': 'Відноситься до:',
  'chooseFixedDateBeforePayment': 'Перш ніж перейти до наступного кроку необхідно вибрати дату для вибраних білетів.',
  'chooseFixedDatetimeBeforePayment': 'Перш ніж перейти до наступного кроку необхідно вибрати годину для вибраної дати.',
  'noFixedDatesForChosenPeriod': 'Немає вільних дат у цей період.',
  'noFixedDatesOnDay': 'Немає доступних термінів на %{date}.',
  'noFixedDatesBetweenDates': 'Немає доступних термінів у дні %{since} - %{until}.',
  'noFixedDatesBeforeDate': 'Немає доступних термінів до %{date}.',
  'noFixedDatesAfterDate': 'Немає доступних термінів після %{date}.',
  'next': 'Далі',
  'back': 'Повернення',
  'onWebsite': 'на сайті',
  'january': 'Січень',
  'february': 'Лютий',
  'march': 'Березень',
  'april': 'Квітень',
  'may': 'Травень',
  'june': 'Червень',
  'july': 'Липень',
  'august': 'Серпень',
  'september': 'Вересень',
  'october': 'Жовтень',
  'november': 'Листопад',
  'december': 'Грудень',
  'sundayShort': 'Нд',
  'sunday': 'Неділя',
  'mondayShort': 'Пн',
  'monday': 'Понеділок',
  'mondayTwoPart': 'Пн',
  'tuesdayTwoPart': 'Вт',
  'wednesdayTwoPart': 'Ср',
  'thursdayTwoPart': 'Чт',
  'fridayTwoPart': 'Пт',
  'saturdayTwoPart': 'Сб',
  'sundayTwoPart': 'Нд',
  'tuesdayShort': 'Вт',
  'tuesday': 'Вівторок',
  'wednesdayShort': 'Ср',
  'wednesday': 'Середа',
  'thursdayShort': 'Чт',
  'thursday': 'Четвер',
  'fridayShort': 'Пт',
  'friday': 'П\'ятниця',
  'saturdayShort': 'Сб',
  'saturday': 'Субота',
  'canNotBuyMoreTickets': 'Не можна купити більше квитків. Немає вільних місць',
  'willEndSoon': 'Незабаром закінчиться!',
  'itemsLeft': 'Залишилося %{limit}!',
  'willEndBefore': 'Пропозиція до %{date}!',
  'soldOut': 'Розпродано!',
  'bookingRequired': 'Після покупки необхідне бронювання.',
  'bookingAfterBuy': 'Після покупки Ви отримаєте %p до бронювання.',
  'phone': 'номер телефону',
  'website': 'веб-сайт',
  'bookingEmail': 'адреса електронної пошти',
  'more': 'більше',
  'less': 'менше',
  'from': 'від',
  'durationUnlimitedSentence': 'Без обмежень у часі',
  'durationUnlimited': 'без обмежень у часі',
  'useNumber': 'Кількість входів',
  'useDatetime': 'Реалізація',
  'betweenDates': 'від %{since} до %{until}',
  'onDate': 'дня %{date}',
  'validBeforeDate': 'до %{date}',
  'validInDays': 'до %{number}',
  'today': 'сьогодні',
  'numberOfDays': 'день |||| д.',
  'activeInDays': 'Tермін придатності: %{number} днів з моменту активації',
  'activationInDays': 'Активація в об\'єкті %{days} після покупки',
  'activationDates': 'Активація в об\'єкті %{dates}',
  'pickFixedDateInTheNextStep': 'Перевірте всі доступні дати на наступному кроці',
  'dateOfUse': 'Дата реалізації',
  'withDiscountLabel': 'У вас є промокод?',
  'optional': 'необов\'язкове',
  'discountCode': 'Код знижки',
  'useDiscountCode': 'Використайте код',
  'discountUsed': 'Активний промокод (%{code}):',
  'invalidDiscountCode': 'Даний код (%{code}) не знижує ціни на обрані послуги.',
  'discountCodeNotFound': 'Цей код (%{code}) прострочений, недійсний або вже використаний.',
  'discountAdditionsNotIncluded': 'Код знижки не може бути застосовано до додатків.',
  'notEligibleDiscountTickets': 'На інші квитки цей код не поширюється.',
  'close': 'закрити',
  'tryAgain': 'повторити спробу',
  'phoneNumber': 'Телефонний номер',
  'contactPhoneNumber': 'Контактний номер телефону',
  'clientPhoneNumber': 'Номер телефону покупця',
  'restCountries': 'Інші країни',
  'sellerIs': 'Продавець -',
  'administratorOfThePersonalData': 'Адміністратором персональних даних є',
  'sellerCopy': 'Обробка персональних даних буде відбуватися з метою виконання замовлення, \\n відповідно до ст. 6, 1. b загального положення про захист персональних даних. Персональні дані зберігатимуться протягом періоду, необхідного для надання послуги. Ви маєте право доступу \\n до своїх даних і право виправляти, видаляти, обмежувати обробку, право на передачу даних, \\n право на заперечення.',
  'infoAboutPrivacyPolicy': 'Інформацію щодо обробки даних можна знайти за посиланням',
  'businessSellerCopy': 'Продавець -',
  'privacyPolicy': 'Політика конфіденційності',
  'privacyPolicyAccept': 'Політику конфіденційності',
  'termsOfServiceAgreement': 'Я прочитав(-ла) і приймаю умови',
  'termsOfService': 'Правила продажу',
  'inTermsOfService': 'Регламенті',
  'inPrivacyPolicy': 'Політиці конфіденційності',
  'and': 'та',
  'acceptedTermsOfServiceIn': 'З прийнятими умовами співпраці можна ознайомитися в',
  'facilityTermsOfService': 'Регламент %{facilityName}',
  'activitiesLimitExceeded': 'Хтось здійснив покупку і кількість доступних квитків зменшилась. Виберіть інше.',
  'invalidDataOrCharacters': 'Дані неправильні або містять недопустимі символи. Виправте їх і спробуйте ще раз.',
  'invalidAdmissionDate': 'Вказана дата неправильна.',
  'internalErrorMessage': 'Сталася помилка. Спробуйте ще раз.',
  'widgetDisabled': 'На даний момент онлайн-продаж вимкнено.',
  'bubbleDisabled': 'Розпродаж без урахування!',
  'buyOnline': 'Купи онлайн!',
  'buyVoucher': 'Купи ваучер',
  'buyTicket': 'Купи квиток!',
  'bookingOnline': 'Замовляй онлайн',
  'buySkipass': 'Купи скіпас',
  'buyCourse': 'Купи курс',
  'iWantInvoice': 'Я хочу отримати фактуру',
  'address': 'Вулиця та будинок / номер квартири',
  'city': 'Місто',
  'postalCode': 'Поштовий індекс',
  'companyName': 'Назва компанії',
  'requiredOnlyForInvoices': 'Обов’язковий лише для фактур для фірм',
  'companyNIP': 'Ідентифікаційний податковий номер компанії (NIP)',
  'optionalSentence': 'За бажанням',
  'invalidPrice': 'Ви не можете перейти до оплати, оскільки відображена ціна відрізняється від фактичної ціни - оновіть сторінку, щоб \\n завантажити актуальні дані.',
  'paymentDelayedInfo': 'Суми до сплати за замовлення мають бути погашені на основі фактури з ПДВ, яку виставила фірма %{companyName}.',
  'oncePerDay': 'Один вхід на день',
  'withoutLimit': 'Немає добового обмеження на вхід',
  'ticketsReady': 'Квиток готовий! |||| Квитки готові!',
  'pleaseWait': 'Будь ласка, зачекайте...',
  'downloadTickets': 'Завантажити квиток |||| Завантажити квитки',
  'download': 'Завантажити',
  'loadingTickets': 'Ваші квитки готуються!',
  'doOrder': 'Замовляю',
  'doReservation': 'Бронюю',
  'generateExampleOrder': 'Генерація зразка замовлення',
  'hotelNight': 'доба',
  'chooseHotelNight': 'Виберіть час перебування',
  'insertHotelNight': 'Введіть час перебування',
  'chooseHotelNightDescription': 'Квитки доступні для гостей лише на час їхнього перебування.',
  'setNarrowedDates': 'Ви повинні вибрати дати початку та закінчення перебування.',
  'endDateCantBeTheSameAsStart': 'Закінчення перебування має бути щонайменше на добу пізніше початку перебування.',
  'save': 'Записати',
  'insertYear': 'Вкажіть рік',
  'hour': 'год',
  'realiseInDay': 'Реалізація дня %{date}',
  'realiseInDateWithTime': 'Реалізація дня %{date}, а годину вибирете на наступному кроці.',
  'missingPaymentProvider': 'Продавець ще не активував прийом платежів. Будь ласка, повторіть спробу незабаром.',
  'seeOtherActivities': 'Перегляньте інші пропозиції',
  'hideOtherActivities': 'Приховайте інші пропозиції',
  'givenDateIsSoldOut': 'Місця на %{date} розпродано.',
  'givenDateIsSoldOutTestB': 'Місця на %{date} продані',
  'isOutOfLimit': 'Недостатньо місць на годину %{time}.',
  'setDifferentHour': 'Кількість вільних місць на %{time} становить: %{numberOfSeats}, будь ласка, оберіть інший час або дату',
  'givenTimeIsSoldOut': 'Місця на %{time} розпродано.',
  'givenTimeIsSoldOutTestB': 'Всі місця на %{time} продані',
  'hasNoSeatsForGivenDate': 'Недостатньо місць на %{date}.',
  'setDifferentDate': 'Кількість доступних місць на %{date} становить: %{availableSeats}, будь ласка, оберіть інший час або дату',
  'seatsSoldOut': 'продано',
  'hasNoSeats': 'немає місць',
  'chooseDifferentDate': 'Будь ласка, виберіть іншу дату',
  'actuallySoldOut': 'Продано!',
  'noDates': 'На даний момент немає дат!',
  'customerData': 'Дані клієнта',
  'optionalText': 'необов\'язкове',
  'numberOfParticipantsLabel': 'Кількість учасників: %{name}',
  'avaliableNumber': 'Залишилось місць: %{number}',
  'filter': 'Фільтр',
  'clear': 'Очистити',
  'checkItOut': 'Ознайомся',
  'remove': 'Видалити',
  'thisMayInterestYou': 'Це може вас зацікавити',
  'requiredActivityVariantsNotPresentInOrder': 'Для придбання потрібен інший варіант. Поверніться до списку послуг і виберіть відповідний варіант.',
  'requiredActivityVariants': 'Чогось не вистачає у Вашому замовленні.',
  'backTo': 'Поверніться до закладки',
  'addSmall': 'додайте',
  'requiredActivity': 'необхідну послугу',
  'oneOfRequiredActivity': 'додайте одну з необхідних послуг',
  'oneOfRequiredActivityVariants': 'принаймні одну з необхідних опцій обслуговування',
  'to': 'До',
  'marketingConsentsAgreement': 'Я хочу підписатися на розсилку (необов\'язково)',
  'marketingConsentsPromo': 'Будьте в курсі всіх акцій та інформації, ми надсилаємо лише найважливіше, обіцяємо нуль спаму!',
  'setAllConsents': 'Відмітити все',
  'InvalidNipFormat': 'Недійсний номер ПДВ',
  'numberOfPeopleInGroup': 'Кількість осіб в групі',
  'paymentFormOutside': 'Форма оплати за межами Droplabs',
  'other': 'Iнший',
  'paymentTerminal': 'Платіжний термінал',
  'cash': 'Готівка',
  'bankTransfer': 'Переказ на рахунок',
  'prefix': 'Префікс',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Період оплати занадто короткий',
  'paymentCompleteUntilDaysLimitExceeded': 'Період оплати занадто довгий',
  'setPaymentDate': 'Встановіть кінцевий термін оплати',
  'setPaymentDateDesc': 'Якщо клієнт не оплатить замовлення до зазначеної дати, місця повертаються до пулу вільних місць.',
  'newOrder': 'Створіть нове замовлення',
  'LastStep': 'Вам залишився останній крок...',
  'copyLinkAndSendToCustomer': 'Скопіюйте посилання та надішліть його клієнту для оплати замовлення.',
  'orClick': 'або натисніть',
  'CopyLink': 'Скопіювати посилання',
  'paymentOutside': 'Оплата за межами Droplabs',
  'paymentOnline': 'Оплата онлайн',
  'cancel': 'Скасувати зміни',
  'setDateAndTime': 'Виберіть дату та час',
  'privatePerson': 'Приватна особа',
  'company': 'Компанія',
  'invoiceOptionalNote': 'Примітка до рахунку (необов\'язково)',
  'youWillReceiveInvoiceSimplified': 'Ви отримаєте квитанцію з податковим номером (NIP), яка є спрощеною рахунком.',
  'nameOptional': 'Ім\'я (необов\'язково)',
  'surnameOptional': 'Прізвище (необов\'язково)',
  'emailOptional': 'Адреса електронної пошти (необов\'язково)',
  'isExpired': 'минула термін дії',
  'isAdmissionDateInvalid': 'стосується іншого терміну виконання',
  'isInvalid': 'недійсний',
  'isUsed': 'вже використаний',
  'buyerData': 'Дані покупця',
  'requiredEmailAlert': 'Не забудьте вказати електронну адресу клієнта, інакше він не отримає рахунок-фактуру.',
  'issueAnInvoice': 'Виставити рахунок-фактуру',
  'areYouLeaving': 'Вже виходите на вулицю?',
  'exitConfirmationComment': 'Купуючи зараз, ви гарантуєте незмінну ціну. Якщо ви підете, ви втратите кроки, які ви виконали до цього часу.',
  'abandonPurchase': 'Відмовитися від покупки',
  'continueShopping': 'Продовжити покупки',
  'thisFeatureIsNotEnabled': 'Ця функція недоступна',
  'thisFeatureIsNotEnabledSolution': 'Нічого не втрачено! Напишіть нам на %p, ми допоможемо вибрати правильний план.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Будь ласка, зачекайте',
  'waitingForPaymentConfirmation': 'Ми очікуємо підтвердження оплати.',
  'paymentProcessInterrupted': 'Якщо процес оплати був перерваний, ви можете оплатити замовлення',
  'clickHere': 'натиснувши тут',
  'ticketsLoadedAndSentToEmail': 'Квитки будуть завантажені нижче та відправлені на вашу електронну адресу:',
  'paymentAccepted': 'Платіж прийнято',
  'orderNumber': 'Номер замовлення:',
  'orderSendOnEmail': 'Підтвердження замовлення надіслано на електронну пошту:',
  'emailNotGetThrough': 'Повідомлення не надійшло?',
  'emailNotGetThroughTooltip': 'Якщо ви не бачите повідомлення протягом кількох хвилин після здійснення платежу, перевірте папку спаму. Якщо це не допоможе, введіть \'Droplabs\' у пошуковій системі вашої поштової програми.',
  'orderCreated': 'Замовлення створено',
  'generatingTickets': 'Генерація квитків',
  'generatingTicketsDescription': 'Незабаром ви зможете завантажити свій квиток та додати його до віртуального гаманця. Ми також відправили копію того ж квитка на вказану адресу електронної пошти.',
  'everythingDone': 'Все готово!',
  'ticketsReadyToDownload': 'Квитки готові до завантаження. Натисніть кнопку для завантаження.',
  'showTickets': 'Показати квитки',
  'hideTickets': 'Приховати квитки',
  'pdfDocumentIsReady': 'Успіх, PDF готово!',
  'clickForPdfDocument': 'PDF готово до завантаження. Натисніть кнопку для завантаження.',
  'pdfDocumentIsGenerating': 'Генерація PDF',
  'pdfDocumentIsGeneratingDescription': 'Генерація PDF може зайняти деякий час, ви не повинні чекати.',
  'generating': 'Генерація...',
  'sendOrder': 'Відправити замовлення',
  'sendOrderDescription': 'Вкажіть електронну адресу, на яку має бути надіслано замовлення разом з квитком.',
  'orderSendedOnEmail': 'Замовлення було відправлено на вказану електронну адресу',
  'orderSomethingElse': 'Бажаєте замовити щось ще?',
  'actionDisabledInPreviewMode': 'Ця дія недоступна, цей режим призначений лише для попереднього перегляду.',
  'anotherRecipient': 'Я хочу вказати іншого одержувача',
  'recipientName': 'Ім\'я отримувача',
  'digitalShippingMethod': 'Електронна відправка на вказану електронну адресу',
  'somethingIsWrong': 'Щось пішло не так...',
  'waitingForPaymentError': 'Ваше замовлення було скасовано або час оплати закінчився. Створіть нове замовлення або поновіть оплату.',
  'waitingForPayment': 'Очікуємо на оплату',
  'waitingForPaymentDesc': 'Сума замовлення відправлена на термінал Viva Wallet. Прийміть платіж, як тільки сума з\'явиться на екрані терміналу. Якщо ви не бажаєте приймати платіж, скасуйте транзакцію.',
  'sendAgain': 'Повторно відправити',
  'paymentRepeatError': 'Платіж не вдалося відправити',
  'paymentRepeatSucces': 'Платіж було відправлено',
  'rePayment': 'Повторити оплату',
  'setTicketAsUsed': 'Позначити як використаний',
  'setTicketAsUsedDescription': 'Зареєструйте використання всіх новостворених квитків, які діють сьогодні.',
  'useTickets': 'Використовувати квитки',
  'useTicketsSuccess': 'Квитки успішно позначені як використані.',
  'useTicketsError': 'Неможливо позначити квитки як використані.',
  'mismatchedTicketDate': 'Замовлення стосується іншої дати виконання.',
  'ticketInactive': 'Замовлення не активне.',
  'useTicketsErrorMoreDetails': 'Деталі замовлення можна знайти в розділі \'Підтримка клієнтів\' або \'Контроль доступу\'.',
  'country': 'Країна',
  'notSavedData': 'Дані не будуть збережені',
  'notSavedDataDesc': 'Якщо ви ввели додаткові дані, зверніть увагу, що ця інформація буде втрачена, коли ви перейдете до вибору послуг.',
  'changeActivities': 'змінити елементи',
  'AD': 'Андорра',
  'AE': 'Обʼєднані Арабські Емірати',
  'AF': 'Афганістан',
  'AG': 'Антигуа і Барбуда',
  'AI': 'Ангілья',
  'AL': 'Албанія',
  'AM': 'Вірменія',
  'AO': 'Ангола',
  'AQ': 'Антарктида',
  'AR': 'Аргентина',
  'AS': 'Американське Самоа',
  'AT': 'Австрія',
  'AU': 'Австралія',
  'AW': 'Аруба',
  'AX': 'Аландські острови',
  'AZ': 'Азербайджан',
  'BA': 'Боснія і Герцеговина',
  'BB': 'Барбадос',
  'BD': 'Бангладеш',
  'BE': 'Бельгія',
  'BF': 'Буркіна-Фасо',
  'BG': 'Болгарія',
  'BH': 'Бахрейн',
  'BI': 'Бурунді',
  'BJ': 'Бенін',
  'BL': 'Сен-Бартельмі',
  'BM': 'Бермуди',
  'BN': 'Бруней',
  'BO': 'Болівія (Многонаціональна Держава)',
  'BQ': 'Бонайре, Сінт-Естатіус і Саба',
  'BR': 'Бразилія',
  'BS': 'Багами',
  'BT': 'Бутан',
  'BV': 'Острів Буве',
  'BW': 'Ботсвана',
  'BY': 'Білорусь',
  'BZ': 'Беліз',
  'CA': 'Канада',
  'CC': 'Кокосові (Кілінгові) острови',
  'CD': 'Конго, Демократична Республіка',
  'CF': 'Центральноафриканська Республіка',
  'CG': 'Конго',
  'CH': 'Швейцарія',
  'CI': 'Кот-дʼІвуар',
  'CK': 'Острови Кука',
  'CL': 'Чилі',
  'CM': 'Камерун',
  'CN': 'Китай',
  'CO': 'Колумбія',
  'CR': 'Коста-Ріка',
  'CU': 'Куба',
  'CV': 'Кабо-Верде',
  'CW': 'Кюрасао',
  'CX': 'Острів Різдва',
  'CY': 'Кіпр',
  'CZ': 'Чехія',
  'DE': 'Німеччина',
  'DJ': 'Джибуті',
  'DK': 'Данія',
  'DM': 'Домініка',
  'DO': 'Домініканська Республіка',
  'DZ': 'Алжир',
  'EC': 'Еквадор',
  'EE': 'Естонія',
  'EG': 'Єгипет',
  'EH': 'Західна Сахара',
  'ER': 'Еритрея',
  'ES': 'Іспанія',
  'ET': 'Ефіопія',
  'FI': 'Фінляндія',
  'FJ': 'Фіджі',
  'FK': 'Фолклендські острови (Мальвінські)',
  'FM': 'Мікронезія (Федеративні Штати)',
  'FO': 'Фарерські острови',
  'FR': 'Франція',
  'GA': 'Габон',
  'GB': 'Сполучене Королівство Великої Британії та Північної Ірландії',
  'GD': 'Гренада',
  'GE': 'Грузія',
  'GF': 'Французька Гвіана',
  'GG': 'Ґернсі',
  'GH': 'Гана',
  'GI': 'Ґібралтар',
  'GL': 'Гренландія',
  'GM': 'Гамбія',
  'GN': 'Гвінея',
  'GP': 'Гваделупа',
  'GQ': 'Екваторіальна Гвінея',
  'GR': 'Греція',
  'GS': 'Південна Джорджія і Південні Сандвічеві острови',
  'GT': 'Гватемала',
  'GU': 'Гуам',
  'GW': 'Гвінея-Бісау',
  'GY': 'Гаяна',
  'HK': 'Гонконг',
  'HM': 'Острів Херд і Острови Макдональд',
  'HN': 'Гондурас',
  'HR': 'Хорватія',
  'HT': 'Гаїті',
  'HU': 'Угорщина',
  'ID': 'Індонезія',
  'IE': 'Ірландія',
  'IL': 'Ізраїль',
  'IM': 'Острів Мен',
  'IN': 'Індія',
  'IO': 'Британська територія в Індійському океані',
  'IQ': 'Ірак',
  'IR': 'Іран (Ісламська Республіка)',
  'IS': 'Ісландія',
  'IT': 'Італія',
  'JE': 'Джерсі',
  'JM': 'Ямайка',
  'JO': 'Йорданія',
  'JP': 'Японія',
  'KE': 'Кенія',
  'KG': 'Киргизстан',
  'KH': 'Камбоджа',
  'KI': 'Кірибаті',
  'KM': 'Комори',
  'KN': 'Сент-Кіттс і Невіс',
  'KP': 'Корея (Демократична Народна Республіка)',
  'KR': 'Корея, Республіка',
  'KW': 'Кувейт',
  'KY': 'Кайманові острови',
  'KZ': 'Казахстан',
  'LA': 'Лаоська Народно-Демократична Республіка',
  'LB': 'Ліван',
  'LC': 'Сент-Люсія',
  'LI': 'Ліхтенштейн',
  'LK': 'Шрі-Ланка',
  'LR': 'Ліберія',
  'LS': 'Лесото',
  'LT': 'Литва',
  'LU': 'Люксембург',
  'LV': 'Латвія',
  'LY': 'Лівія',
  'MA': 'Марокко',
  'MC': 'Монако',
  'MD': 'Молдова, Республіка',
  'ME': 'Чорногорія',
  'MF': 'Сен-Мартен (французька частина)',
  'MG': 'Мадагаскар',
  'MH': 'Маршаллові Острови',
  'MK': 'Північна Македонія',
  'ML': 'Малі',
  'MM': 'Мʼянма',
  'MN': 'Монголія',
  'MO': 'Макао',
  'MP': 'Північні Маріанські острови',
  'MQ': 'Мартиніка',
  'MR': 'Мавританія',
  'MS': 'Монтсеррат',
  'MT': 'Мальта',
  'MU': 'Маврикій',
  'MV': 'Мальдіви',
  'MW': 'Малаві',
  'MX': 'Мексика',
  'MY': 'Малайзія',
  'MZ': 'Мозамбік',
  'NA': 'Намібія',
  'NC': 'Нова Каледонія',
  'NE': 'Нігер',
  'NF': 'Острів Норфолк',
  'NG': 'Нігерія',
  'NI': 'Нікарагуа',
  'NL': 'Нідерланди',
  'NO': 'Норвегія',
  'NP': 'Непал',
  'NR': 'Науру',
  'NU': 'Ніуе',
  'NZ': 'Нова Зеландія',
  'OM': 'Оман',
  'PA': 'Панама',
  'PE': 'Перу',
  'PF': 'Французька Полінезія',
  'PG': 'Папуа-Нова Гвінея',
  'PH': 'Філіппіни',
  'PK': 'Пакистан',
  'PL': 'Польща',
  'PM': 'Сен-Пʼєр і Мікелон',
  'PN': 'Піткерн',
  'PR': 'Пуерто-Ріко',
  'PS': 'Палестина, Штат',
  'PT': 'Португалія',
  'PW': 'Палау',
  'PY': 'Парагвай',
  'QA': 'Катар',
  'RE': 'Реюньйон',
  'RO': 'Румунія',
  'RS': 'Сербія',
  'RU': 'Російська Федерація',
  'RW': 'Руанда',
  'SA': 'Саудівська Аравія',
  'SB': 'Соломонові острови',
  'SC': 'Сейшельські Острови',
  'SD': 'Судан',
  'SE': 'Швеція',
  'SG': 'Сінгапур',
  'SH': 'Острів Святої Єлени, Вознесіння і Трістан-да-Кунья',
  'SI': 'Словенія',
  'SJ': 'Свальбард і Ян-Маєн',
  'SK': 'Словаччина',
  'SL': 'Сьєрра-Леоне',
  'SM': 'Сан-Марино',
  'SN': 'Сенегал',
  'SO': 'Сомалі',
  'SR': 'Суринам',
  'SS': 'Південний Судан',
  'ST': 'Сан-Томе і Принсіпі',
  'SV': 'Ель-Сальвадор',
  'SX': 'Сінт-Мартен (голландська частина)',
  'SY': 'Сирійська Арабська Республіка',
  'SZ': 'Есватіні',
  'TC': 'Теркс і Кайкос',
  'TD': 'Чад',
  'TF': 'Французькі Південні та Антарктичні Території',
  'TG': 'Того',
  'TH': 'Таїланд',
  'TJ': 'Таджикистан',
  'TK': 'Токелау',
  'TL': 'Східний Тимор',
  'TM': 'Туркменістан',
  'TN': 'Туніс',
  'TO': 'Тонга',
  'TR': 'Туреччина',
  'TT': 'Тринідад і Тобаго',
  'TV': 'Тувалу',
  'TW': 'Тайвань, провінція Китаю',
  'TZ': 'Танзанія, Обʼєднана Республіка',
  'UA': 'Україна',
  'UG': 'Уганда',
  'UM': 'Менші віддалені острови Сполучених Штатів',
  'US': 'Сполучені Штати Америки',
  'UY': 'Уругвай',
  'UZ': 'Узбекистан',
  'VA': 'Святий Престол',
  'VC': 'Сент-Вінсент і Гренадини',
  'VE': 'Венесуела (Боліваріанська Республіка)',
  'VG': 'Британські Віргінські острови',
  'VI': 'Американські Віргінські острови',
  'VN': 'Вʼєтнам',
  'VU': 'Вануату',
  'WF': 'Уолліс і Футуна',
  'WS': 'Самоа',
  'YE': 'Ємен',
  'YT': 'Майотта',
  'ZA': 'Південно-Африканська Республіка',
  'ZM': 'Замбія',
  'ZW': 'Зімбабве',
  'nearestDate': 'Найближча доступна дата',
  'nearestDateWithHour': '%{day} o %{hour}',
  'tomorrow': 'завтра',
  'paymentMethod': 'Спосіб оплати',
  'secureFormDescription': 'Швидка оплата банківським переказом / кредитною карткою',
  'typeGeneratedBlikCode': 'Введіть одноразовий код, що згенерований у вашому банківському додатку',
  'blik': 'BLIK',
  'typeBlikCode': 'Введіть код BLIK',
  'blikCodePatternError': 'Код повинен містити 6 цифр',
  'blikCode': 'Код BLIK',
  'simplifiedInvoice': 'Спрощений',
  'fullInvoice': 'Повна',
  'privateInvoice': 'Іменний',
  'simplifiedInvoiceInformation': 'Клієнт отримає квитанцію з номером ПДВ як спрощений рахунок-фактуру. Він може бути виданий лише компаніям, зареєстрованим у Польщі. Для іноземних компаній виберіть повний рахунок-фактуру.',
  'isNotPolishNip': 'Вказаний номер NIP не є польським.',
  'polishNip': 'Польський номер ПДВ',
  'checkTheBankingApp': 'Перевірте банківську програму',
  'confirmPaymentWithPin': 'Зараз підтвердіть оплату ПІН-кодом за допомогою додатка на своєму телефоні.',
  'ticketsLoadedAfterConfirm': 'Квитки будуть завантажені після підтвердження оплати та відправлені на електронну пошту:',
  'paymentWithBlik': 'Оплата BLIKом',
  'cannontLoadPage': 'Не вдалося завантажити цю сторінку',
  'refreshPage': 'Оновити сторінку',
  'isSoldOut': 'Все продано.',
  'removeSelectedActivities': 'Видалення вибраних послуг',
  'removeSelectedServicesQuestion': 'Видалити вибрані послуги?',
  'allServicesWillBeRemoved': 'Всі послуги будуть видалені, і ви повернетесь до повторного вибору',
  'hourFull': 'Година.',
  'hasNoSeatsTest': 'Немає місць',
  'pleaseSelectDate': 'Будь ласка, оберіть дату',
  'pleaseSelectTime': 'Будь ласка, оберіть час',
  'selected': 'Вибране',
  'term': 'Дедлайн',
  'price': 'Ціна',
  'startDate': 'Дата початку',
  'endDate': 'Дата закінчення',
  'daysQuanity': 'Кількість днів',
  'minValueError': 'Мінімальне значення — %{value}',
  'maxValueError': 'Максимальне значення — %{value}'
};

/* eslint-enable max-len */
