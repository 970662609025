import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivityItemContent from './ActivityItemContent';
import ActivityVariants from './ActivityVariants';
import {getBasketActivityItems} from '@Features/basket/basketSelectors';
import {useSelector} from 'react-redux';
import {hasActivityValidPrice} from '@Utils/activity/activity';
import ActivityVariantsListToggler from './buttons/ActivityVariantsListToggler';
import {IActivityProcessed} from '@Consts/globals';
import {Box} from '@mui/material';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import {useMatch} from 'react-router-dom';

type Props = {
  activity: IActivityProcessed;
  orderNumber: number;
  indicated?: boolean;
  numberOfActivities: number;
};

const ActivityItemWithVariants = ({activity, indicated, orderNumber, numberOfActivities}: Props) => {
  const isCheckout = !!useMatch(CHECKOUT_PATH);
  const activityBasketItems = useSelector(state => getBasketActivityItems(state, activity.id));
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const [areVariantsVisible, setVariantsVisibility] = useState(
    (Boolean(activityBasketItems.length) ||
    indicated ||
    numberOfActivities === 1) &&
    activityHaveValidPrices
  );
  const [disableEffect, setDisableEffect] = useState(false);

  useEffect(() => {
    if (!areVariantsVisible && !disableEffect) {
      setVariantsVisibility(Boolean(activityBasketItems.length));
    }
  }, [activityBasketItems]);

  function handleVariantsToggle() {
    setDisableEffect(true);
    setVariantsVisibility(!areVariantsVisible);
  }

  return (
    <ActivityItemContent
      activity={activity}
      indicated={indicated}
      numberOfActivities={numberOfActivities}
      picker={(
        <Box sx={{width: {xs: isCheckout ? '158px' : '100%', sm: '158px'}}}>
          <ActivityVariantsListToggler
            onClick={handleVariantsToggle}
            isActive={areVariantsVisible}
          />
        </Box>
      )}
      additionalContent={
        areVariantsVisible ? (
          <ActivityVariants
            activity={activity}
            orderNumber={orderNumber}
          />
        ) : null
      }
    />
  );
};

export default ActivityItemWithVariants;
