const getActivitiesState = state => state.activities || null;

export const getActivitiesStatus = state => {
  const activitiesState = getActivitiesState(state);

  return activitiesState?.status || null;
};

export const getActivities = state => {
  const activitiesState = getActivitiesState(state);

  return activitiesState?.activities || null;
};
