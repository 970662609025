import React from 'react';
import {isActivitySingleGroupEvent} from '@Utils/activity/activity';
import {useDispatch, useSelector} from 'react-redux';
import {modifyBasket} from '@Features/basket/basketActions';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getActivityBasketItems, getVariantBasketItems} from '@Utils/basket';
import {getVariantById} from '@Utils/activity/variant';
import {getFacility} from '@Features/facility/facilitySelectors';
import {isVariantGroup} from '@Utils/variantType';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import ActivityAddButton from './buttons/ActivityAddButton';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import {getVariantMinLimit, getPickerDisableReason, getVariantGroupLimit} from './utils';
import {IBasketItem} from '@Consts/types';
import GroupCounterPicker from './counterPicker/GroupCounterPicker';
import CounterPicker from './counterPicker/CounterPicker';
import {Box} from '@mui/material';

type Props = {
  activity: IActivityProcessed;
  variantId: number;
  orderNumber: number;
  showErrorAbsolute?: boolean;
};

const ActivityVariantPicker = ({activity, variantId, orderNumber, showErrorAbsolute}: Props) => {
  const dispatch = useDispatch();
  const isCheckout = !!useMatch(CHECKOUT_PATH);
  const {id: activityId, passType, variants, admissionPolicy} = activity;
  const variant: IActivityVariantProcessed = getVariantById(variants, variantId);
  const facility = useSelector(getFacility)!;
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const variantBasketItems = getVariantBasketItems(activityBasketItems, activityId, variantId);
  const numberOfItems = getNumberOfItems();
  const {maximumTicketsInOrder} = facility.company;
  const showAddButton = variants.length === 1;
  const isGroupVariantType = isVariantGroup(variant?.type) || isActivitySingleGroupEvent(activity.passType);

  const variantLimit = getVariantMinLimit({
    variant,
    activity,
    maximumTicketsInOrder,
    numberOfBasketItems: basketItems.length,
    numberOfVariantItems: variantBasketItems.length,
    isGroupVariantType
  });

  const disableReason = getPickerDisableReason({
    activity,
    basketItems,
    maximumTicketsInOrder,
    variantId
  });
  const selectedNumberOfParticipants = variantBasketItems[0]?.selections?.numberOfParticipants;
  const selectedNumberOfSeats = variantBasketItems[0]?.selections?.numberOfSeats;

  function getNumberOfItems() {
    if (variantBasketItems.length > 0) {
      if (isVariantGroup(variant?.type)) {
        return variantBasketItems[0].selections.numberOfSeats;
      }

      if (isActivitySingleGroupEvent(passType)) {
        return variantBasketItems[0].selections.numberOfParticipants;
      }
    }

    return variantBasketItems.length;
  }

  const addBasketItems = async (amount: number) => {
    for (let i = 0; i < amount; i++) {
      dispatch(modifyBasket({activity, variantId, orderNumber, isCheckout}, 'add'));
    }
  };

  const removeBasketItems = (amount: number) => {
    for (let i = 0; i < amount; i++) {
      dispatch(modifyBasket({activity, variantId, orderNumber, isCheckout}, 'remove'));
    }
  };

  function handleBasketItemsChange(value: number) {
    const currentPickerElements = selectedNumberOfParticipants ?? selectedNumberOfSeats ?? numberOfItems;
    const shouldAdd = value > currentPickerElements;
    const shouldRemove = value < currentPickerElements;

    if (shouldAdd) {
      addBasketItems(value - currentPickerElements);
    } else if (shouldRemove) {
      removeBasketItems(currentPickerElements - value);
    }
  }

  const handleAddToBasket = () => {
    dispatch(modifyBasket({activity, variantId, orderNumber, isCheckout}, 'add'));
  };

  if (showAddButton && !numberOfItems) {
    return (
      <Box sx={{width: {xs: isCheckout ? '158px' : '100%', sm: '158px'}}}>
        <ActivityAddButton
          onClick={handleAddToBasket}
          disableReason={disableReason}
        />
      </Box>
    );
  }

  const getPickerGroupValue = () => {
    if (isVariantGroup(variant?.type)) {
      return selectedNumberOfSeats ?? 0;
    }

    if (isActivitySingleGroupEvent(passType)) {
      return selectedNumberOfParticipants ?? 0;
    }

    return 0;
  };

  if (isGroupVariantType) {
    return (
      <GroupCounterPicker
        value={getPickerGroupValue()}
        onBlur={handleBasketItemsChange}
        maxValue={getVariantGroupLimit({variant, activity})}
        minValue={variant.minNumberOfSeats ?? admissionPolicy?.minNumberOfParticipants ?? 0}
        showErrorAbsolute={showErrorAbsolute}
        disableReason={disableReason}
        basketItemExists={!!variantBasketItems.length}
        variantId={variantId}
      />
    );
  }

  return (
    <CounterPicker
      value={numberOfItems}
      disableReason={disableReason}
      onBlur={handleBasketItemsChange}
      maxValue={variantLimit}
      minValue={variant.minNumberOfSeats ?? admissionPolicy?.minNumberOfParticipants ?? 0}
      showErrorAbsolute={showErrorAbsolute}
      variantId={variantId}
    />
  );
};

export default ActivityVariantPicker;
