import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CounterInput from '@Components/counterInput/CounterInput';
import {getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {blockNextStepWith, unblockNextStepWith} from '@Features/basket/basketActions';
import {getInvalidFieldMessage} from '../utils';
import {isInteger} from '@Utils/typeGuards';
import locale from '@Utils/locale';

type Props = {
  minValue: number;
  maxValue: number;
  disableReason: string;
  value: number;
  onBlur: (value: number) => void;
  showErrorAbsolute?: boolean;
  variantId: number;
};

const CounterPicker = ({
  minValue,
  maxValue,
  onBlur,
  value,
  disableReason,
  showErrorAbsolute,
  variantId
}: Props) => {
  const [inputValue, setInputValue] = useState<number | ''>(value);
  const limitDisableIncreaseReason = inputValue >= maxValue ? locale.translate('canNotBuyMoreTickets') : null;
  const dispatch = useDispatch();
  const isblockNextStepWith = useSelector(getIsNextStepBlocked);
  const isInputValueValid = isInteger(inputValue) ? inputValue <= maxValue && inputValue >= minValue : true;
  const invalidFieldMessage = getInvalidFieldMessage({maxValue, minValue, inputValue});

  const handleblockNextStepWith = () => {
    if (!isInputValueValid) {
      dispatch(blockNextStepWith(variantId));
    }

    if (isblockNextStepWith && isInputValueValid) {
      dispatch(unblockNextStepWith(variantId));
    }
  };

  const handleInputValueChange = () => {
    if (isInputValueValid && isInteger(inputValue) && inputValue !== 0) {
      return onBlur(inputValue);
    }

    if (inputValue === 0) {
      onBlur(0);
      setInputValue(0);
    }
  };

  useEffect(() => {
    handleblockNextStepWith();
    handleInputValueChange();
  }, [inputValue]);

  const handleOnChange = (value: number | '') => {
    setInputValue(value);
  };

  const handleOnBlur = () => {
    // empty field should be changed to 0
    if (!isInteger(inputValue)) {
      onBlur(0);
      setInputValue(0);
    } else if (isInputValueValid) {
      onBlur(inputValue);
    }
  };

  const handleDecrease = () => {
    const decresedValue = isInteger(inputValue) ? inputValue - 1 : 0;

    setInputValue(decresedValue);
  };

  const handleIncrease = () => {
    const increasedValue = isInteger(inputValue) ? inputValue + 1 : 1;

    setInputValue(increasedValue);
  };

  return (
    <CounterInput
      value={inputValue}
      onDecrease={handleDecrease}
      onIncrease={handleIncrease}
      disableIncreaseReason={disableReason || limitDisableIncreaseReason}
      invalidFieldMessage={invalidFieldMessage}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      maxValue={maxValue}
      showErrorAbsolute={showErrorAbsolute}
    />
  );
};

export default CounterPicker;
