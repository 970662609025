import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import ActivityVariantPicker from './ActivityVariantPicker';
import ActivityItemContent from './ActivityItemContent';

const ActivityItemWithoutVariants = ({activity, indicated, orderNumber, numberOfActivities}) => {
  const {variants} = activity;
  const variantId = variants.length ? variants[0].id : null;

  return (
    <ActivityItemContent
      activity={activity}
      indicated={indicated}
      numberOfActivities={numberOfActivities}
      {...variantId && {picker: (
        <ActivityVariantPicker
          activity={activity}
          variantId={variantId}
          orderNumber={orderNumber}
          showErrorAbsolute
        />
      )}}
    />
  );
};

ActivityItemWithoutVariants.propTypes = {
  activity: PropTypes.shape({
    variants: PropTypes.array.isRequired,
    passType: PropTypes.string.isRequired
  }).isRequired,
  orderNumber: PropTypes.number.isRequired,
  indicated: PropTypes.bool,
  numberOfActivities: PropTypes.number.isRequired
};

export default ActivityItemWithoutVariants;
