/* eslint-disable max-len */

export default {
  'multidayRangeInvalid': 'Vybraný rozsah obsahuje nedostupné výrazy',
  'PLN': 'PLN',
  'EUR': 'EUR',
  'PLNtestB': 'zł',
  'EURtestB': '€',
  'validPostalCodeLength': 'Zadejte platné poštovní směrovací číslo (např. 00-000).',
  'minLength': 'Minimální počet znaků: %{length}',
  'maxLength': 'Maximální počet znaků: %{length}',
  'parcelLockerSelected': 'Vybraná skříňka na balíky:',
  'selectParcelLocker': 'Zvolte skříňku na balíky',
  'selectAnotherParcelLocker': 'Vyberte jinou skříňku na balíky',
  'parcelLockerPointError': 'Nepodařilo se získat správnou adresu skříňky na balíky',
  'enterDeliveryPhoneNumber': 'Zadejte telefonní číslo pro doručení',
  'onlineMethodsDescription': 'U ostatních služeb elektronické doručování.',
  'shippingMethodRequired': 'Než přejdete k platbě, musíte vybrat způsob dopravy.',
  'chooseShippingMethod': 'Zvolte způsob přepravy',
  'generateWithAmount': 'Vygenerovat (%{amountToPay})',
  'additionsHeader': 'Dodatky: %{activityName}',
  'additionsLimitReached': 'Byl dosažen limit pro dodatky',
  'perPersonNumber': '%{number} osob. x',
  'selectedDayIsNotAvailable': 'Datum %{date} není k dispozici',
  'selectedDateIsNotAvailable': 'Datum %{date} není k dispozici',
  'errorOccurredWhileFetchingOrder': 'Vaši objednávku nemůžeme načíst. Pokud jste provedli platbu, zkontrolujte prosím svou poštovní schránku.',
  'priceTooHigh': 'Hodnota vybraných služeb je příliš vysoká. K platbě není možné přistoupit.',
  'invalidNip': 'Číslo DPH je nesprávné',
  'orderCreationError': 'Chyba při vytváření objednávky - zkuste to znovu',
  'activitiesFetchError': 'Nelze načíst aktivity zařízení',
  'chooseLimitReached': 'Dosažený limit vybraných kusů',
  'localTours': 'Místní prohlídky',
  'clauseAcceptanceRequired': 'Vyžadováno přijetí doložky',
  'invalidPostalCode': 'Neplatné poštovní směrovací číslo',
  'invalidNIPLength': 'NIP společnosti má neplatnou délku',
  'invalidPhone': 'Neplatné telefonní číslo',
  'invalidEmail': 'Neplatná e-mailová adresa',
  'invalidFormatError': 'Nesprávný formát',
  'toLongError': 'Maximální počet znaků je 30',
  'toShortError': 'Minimální počet znaků je 3',
  'invalidTypeError': 'Neplatná hodnota',
  'missingFieldError': 'Z tohoto pole nelze získat hodnotu',
  'unknownError': 'Neznámá chyba',
  'errorScriptNotFound': 'Chyba - váš prohlížeč není podporován nebo neplatná konfigurace obchodu',
  'addressRequired': 'Vyžadována adresa',
  'regulationAcceptRequired': 'Vyžaduje se souhlas s podmínkami a zásadami ochrany osobních údajů',
  'regulationsAcceptRequired': 'Vyžadováno přijetí podmínek a zásad ochrany osobních údajů',
  'phoneRequired': 'Vyžadováno telefonní číslo',
  'lastNameRequired': 'Příjmení povinné',
  'firstNameRequired': 'Křestní jméno povinné',
  'emailRequired': 'Požadovaná e-mailová adresa',
  'answerRequired': 'Vyžaduje se odpověď',
  'skippTheLine': 'Přeskočit řádek s lístkem',
  'downloadInPreviewIsDisabled': 'V režimu náhledu se objednávka odešle do e-mailu a nelze ji z tohoto zobrazení stáhnout',
  'noValidDatesRange': 'Není k dispozici žádný platný rozsah termínů',
  'chooseNumberOfDays': 'Zvolte počet dní',
  'day': 'den',
  'perPersonAbbr': 'pp',
  'maxNumberOfParticipants': 'Maximální počet účastníků je %{number}',
  'minNumberOfParticipants': 'Minimální počet účastníků je %{number}',
  'enterNumberOfParticipants': 'Zadejte počet účastníků',
  'numberOfParticipants': 'Počet účastníků',
  'numberOfParticipantsBetween': 'Počet účastníků: od %{min} do %{max}',
  'generate': 'Vytvořit',
  'seats': 'ks.',
  'attention': 'Pozor!',
  'forSinglePerson': 'pro 1 osobu',
  'chooseAdditions': 'Zvolte doplňky',
  'pleaseChooseOneOption': 'Je nutné zvolit jednu možnost',
  'or': 'nebo',
  'addToAppleWallet': 'Přidat do Apple Wallet',
  'realiseOrder': 'Realizovat objednávku',
  'generatingPdf': 'PDF se generuje...',
  'transactionCompletedSuccessfully': 'Transakce byla úspěšně dokončena!',
  'immediatelyProceedThroughPayment': 'Po potvrzení platby se vstupenky načtou níže a odešlou se na %p',
  'optionally': 'volitelné',
  'shopOnline': 'Internetový obchod',
  'orderOnline': 'Objednat online',
  'discountsAreDisabledInPreview': 'Slevy jsou v náhledu vypnuty',
  'contactNumberToClient': 'Kontaktní číslo na klienta, který bude realizovat činnost - shromažďuje se pouze pro potřeby její realizace, např. potřeba kontaktu v případě nahodilé situace.',
  'numberOnlyForRealisation': 'Číslo pouze pro potřeby realizace aktivity',
  'unsuccessfullyOrderedSending': 'Odeslání potvrzení objednávky se nepodařilo na %{email}. Zkuste to později nebo zašlete zprávu na adresu pomoc@droplabs.pl.',
  'successfullyOrderedSending': 'Úspěšně odesláno potvrzení objednávky na adresu %{email}',
  'requiredField': 'Vyžadované pole',
  'forSendingAcceptTos': 'Pro odeslání klient akceptuje',
  'agreementFormWithClause': 'Formulář smlouvy s informační doložkou',
  'send': 'Odeslat',
  'downloadToPrint': 'Stáhnout k tisku',
  'clientEmail': 'E-mailová adresa klienta',
  'chooseSendingConfirmationMethod': 'Připraveno! Zvolte, jakým způsobem chcete zákazníkovi poskytnout potvrzení',
  'orderPreviewInfo': 'Vzorová objednávka byla odeslána na e-mail: %p. Pokud se zpráva nezobrazí do několika minut po provedení platby, zkontrolujte složku se spamem. Pokud to nepomůže, zadejte do vyhledávače svého e-mailového programu "Droplabs".',
  'exceededTimeout': 'Překročení čekací doby na platbu',
  'buyerWillSettleWithYou': 'Osoba, která bude využívat služeb %p, se s vámi vyrovná',
  'youCanPrintTickets': 'Po zakoupení si můžete vytisknout vstupenky připravené k použití. U vstupenek nebude uvedena žádná cena',
  'onlinePaymentForOrder': 'Objednávku zaplatíte online. Faktura bude vystavena společností %p pro vaši společnost za stanovené období, obvykle souhrnně za celý měsíc.',
  'youWillPayForOrderBasedOnInvoice': 'Za objednávku zaplatíte na základě faktury, kterou vystaví %p pro vaši společnost za stanovené období, obvykle souhrnně za celý měsíc.',
  'wholeAmountWillBeChargedForUSed': 'Celou částku bude %p účtovat pouze za použité jízdenky. Společnost %p může účtovat další poplatek, a to i za nevyužité vstupenky.',
  'youCanPrintReservationConfirmation': 'Po provedení rezervace si můžete potvrzení ihned vytisknout.',
  'paymentWillBeChargedOnSite': 'Celou částku objednávky bude nutné uhradit na místě během služby.',
  'missingFacilityId': 'Neplatná konfigurace.',
  'cannotLoadFacility': 'Nelze načíst zařízení %p',
  'add': 'Přidat',
  'choose': 'Zobrazit',
  'choosing': 'Vyberte si',
  'hide': 'Skrýt',
  'expand': 'Rozbalit',
  'menuDetails': 'Podrobnosti',
  'menuSummary': 'Platba',
  'menuSummaryThanks': 'Děkujeme vám za vaši objednávku!',
  'noActivitiesInSales': 'Není z čeho vybírat. Podívejte se na nás brzy!',
  'hours': 'hrs',
  'minutes': 'min',
  'goToTheNextStep': 'Pokračujte',
  'sellerOfActivities': '%{name} je prodejce služeb.',
  'ifYouNeedHelpAssociatedWithOffer': 'Máte dotazy týkající se nabídky? Napište na adresu %p.',
  'saleSupportedByDroplabs': 'Droplabs - elektronický obchod, prodejní a distribuční systém pro vstupenky, vouchery, permanentky a služby.',
  'ifYouNeedTechnicalSupport': 'Potřebujete technickou podporu? Kontaktujte nás na adrese %p',
  'activityAdmissionDateLabel': 'Datum přijetí: %{name}',
  'chooseDayOfUseActivities': 'Vyberte datum vstupu',
  'chooseTimeOfUseActivities': 'Vyberte datum vstupu',
  'chooseNarrowedTimeOfUseActivities': 'Vyberte zúžení data přijetí',
  'chooseTime': 'Zvolte hodinu',
  'chooseEmailForSendingTickets': 'Jaká je vaše e-mailová adresa?',
  'email': 'E-mailová adresa',
  'name': 'Jméno',
  'buyersName': 'Jméno kupujícího',
  'surname': 'Příjmení',
  'buyersSurname': 'Příjmení kupujícího',
  'goToThePayment': 'Provést platbu',
  'orderFreeTickets': 'Objednávka',
  'buyWithoutPayment': 'Koupit a zaplatit později',
  'businessTicketsHaveBeenSentIntoEmail': 'Vstupenka byla odeslána na adresu: |||| Vstupenky byly odeslány na adresu: %p',
  'checkSpamInCaseOfNotReceivingEmail': 'Pokud od nás po několik minut neuvidíte žádný e-mail, zkontrolujte složku se spamem nebo zadejte Droplabs do vyhledávacího řádku ve své poštovní schránce.',
  'buyAnotherActivities': 'Objednejte si další aktivity',
  'noFixedDatesForChosenActivities': 'Pro vybrané služby bohužel nejsou k dispozici žádné volné termíny.',
  'forChosen': 'Platí pro:',
  'chooseFixedDateBeforePayment': 'Před pokračováním prosím vyberte datum přijetí.',
  'chooseFixedDatetimeBeforePayment': 'Před pokračováním prosím vyberte čas přijetí pro vybraný termín.',
  'noFixedDatesForChosenPeriod': 'Pro zvolený termín není k dispozici žádný termín přijetí.',
  'noFixedDatesOnDay': '%{date} není k dispozici.',
  'noFixedDatesBetweenDates': 'V období od %{since} do %{until} nejsou k dispozici žádná data.',
  'noFixedDatesBeforeDate': 'Před %{date} nejsou k dispozici žádná data.',
  'noFixedDatesAfterDate': 'Po %{date} nejsou k dispozici žádná data.',
  'next': 'Další',
  'back': 'Zpět',
  'onWebsite': 'adresa URL rezervace',
  'january': 'Leden',
  'february': 'Únor',
  'march': 'Březen',
  'april': 'Duben',
  'may': 'Květen',
  'june': 'Červen',
  'july': 'Červenec',
  'august': 'Srpen',
  'september': 'Září',
  'october': 'Říjen',
  'november': 'listopad',
  'december': 'prosinec',
  'sundayShort': 'Ne',
  'sunday': 'Neděle',
  'mondayShort': 'Po',
  'monday': 'Pondělí',
  'mondayTwoPart': 'Po',
  'tuesdayTwoPart': 'Út',
  'wednesdayTwoPart': 'St',
  'thursdayTwoPart': 'Čt',
  'fridayTwoPart': 'Pá',
  'saturdayTwoPart': 'So',
  'sundayTwoPart': 'Ne',
  'tuesdayShort': 'Út',
  'tuesday': 'Úterý',
  'wednesdayShort': 'St',
  'wednesday': 'Středa',
  'thursdayShort': 'Čt',
  'thursday': 'Čtvrtek',
  'fridayShort': 'Pá',
  'friday': 'Pátek',
  'saturdayShort': 'So',
  'saturday': 'Sobota',
  'canNotBuyMoreTickets': 'Více této aktivity nelze zakoupit. Žádná místa',
  'willEndSoon': 'Platnost brzy vyprší!',
  'itemsLeft': 'Zbývá %{limit}!',
  'willEndBefore': 'Nabídka platí do %{date}!',
  'soldOut': 'Vyprodáno!',
  'bookingRequired': 'Po zaplacení je nutná rezervace',
  'bookingAfterBuy': 'Po nákupu obdržíte %p za rezervaci',
  'phone': 'telefonní číslo',
  'website': 'webovou adresu',
  'bookingEmail': 'e-mailovou adresu',
  'more': 'více',
  'less': 'méně',
  'from': 'Od',
  'durationUnlimitedSentence': 'Celý den',
  'durationUnlimited': 'celý den',
  'useNumber': 'Počet vstupů',
  'useDatetime': 'Přijetí',
  'betweenDates': 'mezi %{since} a %{until}',
  'onDate': 'dne %{date}',
  'validBeforeDate': 'před %{date}',
  'validInDays': 'v %{number}',
  'today': 'dnes',
  'numberOfDays': 'den |||| dnů',
  'activeInDays': 'Platí: %{number} dní po aktivaci',
  'activationInDays': 'Aktivace v zařízení %{days} po zakoupení',
  'activationDates': 'Aktivace v zařízení %{dates}',
  'pickFixedDateInTheNextStep': 'V dalším kroku zkontrolujte všechny dostupné termíny',
  'dateOfUse': 'Datum přijetí',
  'withDiscountLabel': 'Máte slevový kód?',
  'optional': 'nepovinné',
  'discountCode': 'Kód slevy',
  'useDiscountCode': 'Použijte kód',
  'discountUsed': 'Aktivní slevový kód (%{code}):',
  'invalidDiscountCode': 'Slevový kód (%{code}) nefunguje pro vybrané služby.',
  'discountCodeNotFound': 'Slevový kód (%{code}) je neplatný nebo zastaralý.',
  'discountAdditionsNotIncluded': 'Na dodatky se sleva nevztahuje.',
  'notEligibleDiscountTickets': 'Na ostatní vstupenky se tento kód nevztahuje',
  'close': 'zavřít',
  'tryAgain': 'zkuste to znovu',
  'phoneNumber': 'Telefonní číslo',
  'contactPhoneNumber': 'Kontaktní telefonní číslo',
  'clientPhoneNumber': 'Telefonní číslo kupujícího',
  'restCountries': 'Ostatní země',
  'sellerIs': 'Prodávajícím a',
  'administratorOfThePersonalData': 'Správcem vašich osobních údajů je',
  'sellerCopy':
    `Vaše údaje budou zpracovávány zejména za účelem plnění smlouvy, tj. abyste si mohli zakoupit letenku, a to na základě čl. 6 odst. 1 písm. b) nařízení ONOOÚ.
    Máte právo na přístup k obsahu svých údajů a právo na opravu, výmaz, omezení zpracování, právo na přenositelnost údajů, právo vznést námitku a podat stížnost u dozorového úřadu.
    Vaše údaje nejsou předmětem automatizovaného rozhodování, včetně profilování.`,
  'infoAboutPrivacyPolicy': 'Úplný obsah informační doložky je k dispozici zde',
  'businessSellerCopy': 'Prodávající je',
  'privacyPolicy': 'Zásady ochrany osobních údajů',
  'privacyPolicyAccept': 'Zásady ochrany osobních údajů',
  'termsOfServiceAgreement': 'Souhlasím s',
  'termsOfService': 'Podmínky poskytování služeb',
  'inTermsOfService': 'Podmínky služby',
  'inPrivacyPolicy': 'Zásady ochrany osobních údajů',
  'and': 'a',
  'acceptedTermsOfServiceIn': 'Přijaté podmínky najdete v',
  'facilityTermsOfService': 'Podmínky poskytování služeb %{facilityName}',
  'activitiesLimitExceeded': 'Aha, máme vyprodáno pro vybrané služby. Zakupte si prosím jiné služby.',
  'invalidDataOrCharacters': 'Poskytnuté údaje jsou neplatné nebo obsahují neplatné znaky. Změňte je a zkuste to znovu.',
  'invalidAdmissionDate': 'Datum je neplatné.',
  'internalErrorMessage': 'Chyba, zkuste to prosím později.',
  'widgetDisabled': 'Prodej je zakázán. Zkuste to znovu později.',
  'bubbleDisabled': 'Výprodej vyloučen!',
  'buyOnline': 'Koupit online!',
  'buyVoucher': 'Koupit voucher',
  'buyTicket': 'Kupte si vstupenku!',
  'bookingOnline': 'Rezervujte online',
  'buySkipass': 'Koupit skipas',
  'buyCourse': 'Koupit kurz',
  'iWantInvoice': 'Chci obdržet fakturu',
  'address': 'Ulice a číslo domu / bytu',
  'city': 'Město',
  'postalCode': 'Poštovní směrovací číslo',
  'companyName': 'Název společnosti',
  'requiredOnlyForInvoices': 'Vyžadováno pouze pro firemní faktury',
  'companyNIP': 'Daňové identifikační číslo',
  'optionalSentence': 'Nepovinné',
  'invalidPrice': 'Nelze přejít k platbě, protože viditelná cena je jiná než skutečná - obnovte prosím stránku, aby se načetly nové údaje',
  'paymentDelayedInfo': 'Platba poplatků za objednávku je vyžadována na základě faktury s DPH, kterou poskytne %{companyName}.',
  'oncePerDay': 'Zadání jednou denně',
  'withoutLimit': 'Zadání bez denního limitu',
  'ticketsReady': 'Vstupenka je připravena! |||| Vstupenky jsou připraveny!',
  'pleaseWait': 'Počkejte prosím...',
  'downloadTickets': 'Stáhnout vstupenku |||| Stáhnout vstupenky',
  'download': 'Stáhnout',
  'loadingTickets': 'Připravujeme vaše vstupenky!',
  'doOrder': 'Objednávka na',
  'doReservation': 'Rezervace',
  'generateExampleOrder': 'Vygenerovat příklad objednávky',
  'hotelNight': 'hotelová noc',
  'chooseHotelNight': 'Zvolte období ubytování',
  'insertHotelNight': 'Přidejte období ubytování',
  'chooseHotelNightDescription': 'Záznamy pro hosty jsou pouze v době ubytování.',
  'setNarrowedDates': 'Musíte zvolit datum začátku a konce ubytování',
  'endDateCantBeTheSameAsStart': 'Konec ubytování musí být alespoň o jeden den později než začátek ubytování.',
  'save': 'Uložit',
  'insertYear': 'Vložte rok',
  'hour': 'hodina',
  'realiseInDay': 'Realizace na %{date}',
  'realiseInDateWithTime': 'Realizace dne %{date}, V dalším kroku zvolíte čas.',
  'missingPaymentProvider': 'Platba ještě nebyla prodávajícím aktivována. Zkuste to později.',
  'seeOtherActivities': 'Podívejte se na další aktivity',
  'hideOtherActivities': 'Skrýt další aktivity',
  'givenDateIsSoldOut': 'Místa na %{date} byla vyprodána',
  'givenDateIsSoldOutTestB': 'Místa na %{date} byla vyprodána',
  'isOutOfLimit': 'Na %{time} nejsou žádná místa',
  'setDifferentHour': 'Počet volných míst pro %{time} je: %{numberOfSeats}, vyberte jiný čas nebo datum',
  'givenTimeIsSoldOut': 'Místa na %{time} byla vyprodána',
  'givenTimeIsSoldOutTestB': 'Místa na %{time} jsou vyprodána',
  'hasNoSeatsForGivenDate': 'Na %{date} nejsou žádná místa.',
  'setDifferentDate': 'Počet volných míst pro %{date} je: %{availableSeats}, Vyberte si jiný termín',
  'seatsSoldOut': 'vyprodáno',
  'hasNoSeats': 'žádná místa',
  'chooseDifferentDate': 'Zvolte jiné datum',
  'actuallySoldOut': 'Skutečně vyprodáno!',
  'noDates': 'V současné době nejsou k dispozici žádné termíny!',
  'customerData': 'Údaje o zákaznících',
  'optionalText': 'volitelné',
  'numberOfParticipantsLabel': 'Počet účastníků: %{name}',
  'avaliableNumber': 'Zbývající místa: %{number}',
  'filter': 'Filtr',
  'clear': 'Vyčistěte',
  'checkItOut': 'Podívejte se na to',
  'remove': 'Odstranit',
  'thisMayInterestYou': 'To by vás mohlo zajímat',
  'requiredActivityVariantsNotPresentInOrder': 'Pro nákup je nutná jiná varianta. Vraťte se na seznam služeb a vyberte příslušnou variantu.',
  'requiredActivityVariants': 'V objednávce vám něco chybí.',
  'backTo': 'Návrat na kartu',
  'addSmall': 'přidat',
  'requiredActivity': 'požadovanou službu',
  'oneOfRequiredActivity': 'alespoň jedna požadovaná služba',
  'oneOfRequiredActivityVariants': 'alespoň jednu z požadovaných možností služeb',
  'to': 'Do',
  'marketingConsentsAgreement': 'Chci se přihlásit k odběru newsletteru (nepovinné)',
  'marketingConsentsPromo': 'Zůstaňte informováni o všech akcích a informacích, posíláme jen to nejdůležitější, slibujeme, že nebudeme zasílat žádný spam!',
  'setAllConsents': 'Označte všechny',
  'InvalidNipFormat': 'Neplatné DIČ',
  'numberOfPeopleInGroup': 'Počet lidí ve skupině',
  'paymentFormOutside': 'Forma platby mimo Droplabs',
  'other': 'Další',
  'paymentTerminal': 'Platební terminál',
  'cash': 'Hotovost',
  'bankTransfer': 'Převod na účet',
  'prefix': 'Předpona',
  'paymentCompleteUntilBeforeMinimumDateTime': 'Doba splatnosti je příliš krátká',
  'paymentCompleteUntilDaysLimitExceeded': 'Doba splatnosti je příliš dlouhá',
  'setPaymentDate': 'Nastavení termínu platby',
  'setPaymentDateDesc': 'Pokud zákazník nezaplatí za objednávku do stanoveného data, místa se vrátí do fondu volných míst.',
  'newOrder': 'Vytvoření nové objednávky',
  'LastStep': 'Před vámi je poslední krok...',
  'copyLinkAndSendToCustomer': 'Zkopírujte odkaz a odešlete jej zákazníkovi, aby mohl objednávku zaplatit.',
  'orClick': 'nebo klikněte na',
  'CopyLink': 'Kopírovat odkaz',
  'paymentOutside': 'Platba mimo Droplabs',
  'paymentOnline': 'Online platba',
  'cancel': 'Zrušení',
  'setDateAndTime': 'Výběr data a času',
  'privatePerson': 'Soukromá osoba',
  'company': 'Firma',
  'invoiceOptionalNote': 'Poznámka k fakturě (volitelné)',
  'youWillReceiveInvoiceSimplified': 'Obdržíte paragon s DIČ, který je zjednodušenou fakturou.',
  'nameOptional': 'Křestní jméno (nepovinné)',
  'surnameOptional': 'Příjmení (nepovinné)',
  'emailOptional': 'E-mailová adresa (nepovinné)',
  'isExpired': 'vypršela platnost',
  'isAdmissionDateInvalid': 'se týká jiné doby realizace',
  'isInvalid': 'je neplatný',
  'isUsed': 'je použitý',
  'buyerData': 'Údaje kupujícího',
  'requiredEmailAlert': 'Nezapomeňte uvést e-mailovou adresu klienta, jinak nedostane fakturu.',
  'issueAnInvoice': 'Vystavit fakturu',
  'areYouLeaving': 'Už jdete ven?',
  'exitConfirmationComment': 'Nákupem nyní získáte záruku nezměněné ceny. Pokud odejdete, ztratíte dosud provedené kroky.',
  'abandonPurchase': 'Opuštění nákupu',
  'continueShopping': 'Pokračovat v nákupu',
  'thisFeatureIsNotEnabled': 'Tato funkce není k dispozici',
  'thisFeatureIsNotEnabledSolution': 'Není co ztratit! Napište nám na %p, pomůžeme vám vybrat ten správný plán.',
  'droplabsHelpMail': 'pomoc@droplabs.pl',
  'pleaseWaitSummary': 'Počkejte prosím',
  'waitingForPaymentConfirmation': 'Očekáváme potvrzení o provedení platby.',
  'paymentProcessInterrupted': 'Pokud byl platební proces přerušen, můžete zaplatit objednávku',
  'clickHere': 'kliknutím sem',
  'ticketsLoadedAndSentToEmail': 'Vstupenky se načtou níže a budou zaslány na váš email:',
  'paymentAccepted': 'Platba přijata',
  'orderNumber': 'Číslo objednávky:',
  'orderSendOnEmail': 'Potvrzení objednávky zaslané e-mailem:',
  'emailNotGetThrough': 'Zpráva nedorazila?',
  'emailNotGetThroughTooltip': 'Pokud nevidíte zprávu do několika minut od provedení platby, zkontrolujte složku se spamem. Pokud to nepomůže, napište \'Droplabs\' do vyhledávání ve svém e-mailovém klientu.',
  'orderCreated': 'Objednávka vytvořena',
  'generatingTickets': 'Generování vstupenek',
  'generatingTicketsDescription': 'Brzy budete moci stáhnout svůj lístek a přidat ho do virtuální peněženky. Poslali jsme také kopii stejného lístku na vámi uvedenou e-mailovou adresu.',
  'everythingDone': 'Vše je hotovo!',
  'ticketsReadyToDownload': 'Vstupenky jsou připraveny ke stažení. Klikněte na tlačítko pro stažení.',
  'showTickets': 'Zobrazit vstupenky',
  'hideTickets': 'Skrýt vstupenky',
  'pdfDocumentIsReady': 'Úspěch, PDF je připraveno!',
  'clickForPdfDocument': 'PDF je připraveno ke stažení. Klikněte na tlačítko pro stažení.',
  'pdfDocumentIsGenerating': 'Generování PDF',
  'pdfDocumentIsGeneratingDescription': 'Generování PDF může chvíli trvat, nemusíte čekat.',
  'generating': 'Generování...',
  'sendOrder': 'Odeslat objednávku',
  'sendOrderDescription': 'Zadejte e-mailovou adresu, na kterou má být objednávka spolu s vstupenkou zaslána.',
  'orderSendedOnEmail': 'Objednávka byla zaslána na zadanou e-mailovou adresu',
  'orderSomethingElse': 'Chcete si objednat ještě něco jiného?',
  'actionDisabledInPreviewMode': 'Tuto akci nelze provést, tento režim je pouze pro náhled.',
  'anotherRecipient': 'Chci zadat jiného příjemce',
  'recipientName': 'Jméno příjemce',
  'digitalShippingMethod': 'Elektronické odeslání na určenou e-mailovou adresu',
  'somethingIsWrong': 'Něco se pokazilo...',
  'waitingForPaymentError': 'Vaše objednávka byla zrušena nebo vypršela lhůta pro zaplacení. Vytvořte novou objednávku nebo obnovte platbu.',
  'waitingForPayment': 'Čekání na platbu',
  'waitingForPaymentDesc': 'Částka objednávky byla odeslána na terminál Viva Wallet. Přijměte platbu, jakmile se částka objeví na obrazovce terminálu. Pokud si nepřejete platbu přijmout, transakci zrušte.',
  'sendAgain': 'Opětovné odeslání',
  'paymentRepeatError': 'Platbu se nepodařilo znovu odeslat',
  'paymentRepeatSucces': 'Platba byla znovu odeslána',
  'rePayment': 'Znovu zaplatit',
  'setTicketAsUsed': 'Označit jako použité',
  'setTicketAsUsedDescription': 'Přihlásit využití všech právě vygenerovaných vstupenek, platných dnes.',
  'useTickets': 'Použít vstupenky',
  'useTicketsSuccess': 'Vstupenky byly úspěšně označeny jako použité.',
  'useTicketsError': 'Nelze označit vstupenky jako použité.',
  'mismatchedTicketDate': 'Objednávka se týká jiného termínu realizace.',
  'ticketInactive': 'Objednávka není aktivní.',
  'useTicketsErrorMoreDetails': 'Podrobnosti o objednávce můžete získat v sekci \'Zákaznická podpora\' nebo \'Kontrola přístupu\'.',
  'country': 'Země',
  'notSavedData': 'Data nebudou uložena',
  'notSavedDataDesc': 'Pokud jste zadali další údaje, mějte na paměti, že se tyto informace ztratí, když přejdete k výběru služeb.',
  'changeActivities': 'Změnit položky',
  'AD': 'Andorra',
  'AE': 'United Arab Emirates',
  'AF': 'Afghanistan',
  'AG': 'Antigua a Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albánie',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antarctica',
  'AR': 'Argentina',
  'AS': 'American Samoa',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Ålandské ostrovy',
  'AZ': 'Azerbaijan',
  'BA': 'Bosna a Hercegovina',
  'BB': 'Barbados',
  'BD': 'Bangladéš',
  'BE': 'Belgie',
  'BF': 'Burkina Faso',
  'BG': 'Bulharsko',
  'BH': 'Bahrajn',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint Barthélemy',
  'BM': 'Bermudy',
  'BN': 'Brunej Darussalam',
  'BO': 'Bolivia (Plurinational State of)',
  'BQ': 'Bonaire, Sint Eustatius a Saba',
  'BR': 'Brazílie',
  'BS': 'Bahamy',
  'BT': 'Bhútán',
  'BV': 'Bouvetův ostrov',
  'BW': 'Botswana',
  'BY': 'Bělorusko',
  'BZ': 'Belize',
  'CA': 'Canada',
  'CC': 'Cocos (Keeling) Islands',
  'CD': 'Kongo, Demokratická republika',
  'CF': 'Středoafrická republika',
  'CG': 'Congo',
  'CH': 'Switzerland',
  'CI': 'Côte d\'Ivoire',
  'CK': 'Cookovy ostrovy',
  'CL': 'Chile',
  'CM': 'Cameroon',
  'CN': 'China',
  'CO': 'Kolumbie',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Cabo Verde',
  'CW': 'Curaçao',
  'CX': 'Christmas Island',
  'CY': 'Kypr',
  'CZ': 'Czechia',
  'DE': 'Germany',
  'DJ': 'Djibouti',
  'DK': 'Dánsko',
  'DM': 'Dominica',
  'DO': 'Dominikánská republika',
  'DZ': 'Alžírsko',
  'EC': 'Ecuador',
  'EE': 'Estonsko',
  'EG': 'Egypt',
  'EH': 'Západní Sahara',
  'ER': 'Eritrea',
  'ES': 'Španělsko',
  'ET': 'Etiopie',
  'FI': 'Finsko',
  'FJ': 'Fidži',
  'FK': 'Falklandy (Malvíny)',
  'FM': 'Mikronésie (Federativní státy)',
  'FO': 'Faerské ostrovy',
  'FR': 'France',
  'GA': 'Gabon',
  'GB': 'Spojené království Velké Británie a Severního Irska',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'Francouzská Guyana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Greenland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Equatorial Guinea',
  'GR': 'Greece',
  'GS': 'Jižní Georgie a Jižní Sandwichovy ostrovy',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hongkong',
  'HM': 'Heard Island and McDonald Islands',
  'HN': 'Honduras',
  'HR': 'Chorvatsko',
  'HT': 'Haiti',
  'HU': 'Maďarsko',
  'ID': 'Indonesia',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IM': 'Isle of Man',
  'IN': 'India',
  'IO': 'British Indian Ocean Territory',
  'IQ': 'Iraq',
  'IR': 'Írán (Islámská republika)',
  'IS': 'Island',
  'IT': 'Itálie',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordánsko',
  'JP': 'Japonsko',
  'KE': 'Keňa',
  'KG': 'Kyrgyzstán',
  'KH': 'Kambodža',
  'KI': 'Kiribati',
  'KM': 'Komory',
  'KN': 'Svatý Kryštof a Nevis',
  'KP': 'Korejská lidově demokratická republika',
  'KR': 'Korejská republika',
  'KW': 'Kuvajt',
  'KY': 'Kajmanské ostrovy',
  'KZ': 'Kazachstán',
  'LA': 'Laoská lidově demokratická republika',
  'LB': 'Libanon',
  'LC': 'Saint Lucia',
  'LI': 'Lichtenštejnsko',
  'LK': 'Srí Lanka',
  'LR': 'Libérie',
  'LS': 'Lesotho',
  'LT': 'Litva',
  'LU': 'Lucembursko',
  'LV': 'Lotyšsko',
  'LY': 'Libye',
  'MA': 'Maroko',
  'MC': 'Monaco',
  'MD': 'Moldova, Republic of',
  'ME': 'Montenegro',
  'MF': 'Saint Martin (francouzská část)',
  'MG': 'Madagaskar',
  'MH': 'Marshallovy ostrovy',
  'MK': 'Severní Makedonie',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolsko',
  'MO': 'Macao',
  'MP': 'Severní Mariany',
  'MQ': 'Martinique',
  'MR': 'Mauritánie',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Maledivy',
  'MW': 'Malawi',
  'MX': 'Mexico',
  'MY': 'Malaysia',
  'MZ': 'Mozambik',
  'NA': 'Namibie',
  'NC': 'Nová Kaledonie',
  'NE': 'Niger',
  'NF': 'Norfolk Island',
  'NG': 'Nigeria',
  'NI': 'Nikaragua',
  'NL': 'Nizozemsko, Nizozemské království',
  'NO': 'Norsko',
  'NP': 'Nepál',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'Nový Zéland',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'Francouzská Polynésie',
  'PG': 'Papua Nová Guinea',
  'PH': 'Filipíny',
  'PK': 'Pákistán',
  'PL': 'Polsko',
  'PM': 'Saint Pierre a Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palestina, stát',
  'PT': 'Portugalsko',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Réunion',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Ruská federace',
  'RW': 'Rwanda',
  'SA': 'Saúdská Arábie',
  'SB': 'Šalamounovy ostrovy',
  'SC': 'Seychely',
  'SD': 'Sudan',
  'SE': 'Švédsko',
  'SG': 'Singapore',
  'SH': 'Svatá Helena, Ascension a Tristan da Cunha',
  'SI': 'Slovinsko',
  'SJ': 'Svalbard a Jan Mayen',
  'SK': 'Slovensko',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somálsko',
  'SR': 'Surinam',
  'SS': 'Jižní Súdán',
  'ST': 'Svatý Tomáš a Princův ostrov',
  'SV': 'Salvador',
  'SX': 'Sint Maarten (nizozemská část)',
  'SY': 'Syrská arabská republika',
  'SZ': 'Eswatini',
  'TC': 'Turks a Caicos',
  'TD': 'Čad',
  'TF': 'Francouzská jižní území',
  'TG': 'Togo',
  'TH': 'Thajsko',
  'TJ': 'Tádžikistán',
  'TK': 'Tokelau',
  'TL': 'Východní Timor',
  'TM': 'Turkmenistán',
  'TN': 'Tunisko',
  'TO': 'Tonga',
  'TR': 'Türkiye',
  'TT': 'Trinidad a Tobago',
  'TV': 'Tuvalu',
  'TW': 'Tchaj-wan, Čínská provincie',
  'TZ': 'Tanzanie, Sjednocená republika',
  'UA': 'Ukraine',
  'UG': 'Uganda',
  'UM': 'United States Minor Outlying Islands',
  'US': 'United States of America',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VA': 'Svatý stolec',
  'VC': 'Saint Vincent and the Grenadines',
  'VE': 'Venezuela (Bolívarovská republika)',
  'VG': 'Panenské ostrovy (Britské)',
  'VI': 'Panenské ostrovy (USA)',
  'VN': 'Viet Nam',
  'VU': 'Vanuatu',
  'WF': 'Wallis a Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'Jihoafrická republika',
  'ZM': 'Zambie',
  'ZW': 'Zimbabwe',
  'nearestDate': 'Nejbližší dostupné datum',
  'nearestDateWithHour': '%{day} v %{hour}',
  'tomorrow': 'zítra',
  'paymentMethod': 'Způsob platby',
  'secureFormDescription': 'Zaplaťte rychlým převodem / platební kartou',
  'typeGeneratedBlikCode': 'Zadejte jednorázový kód vygenerovaný ve vaší bankovní aplikaci',
  'blik': 'BLIK',
  'typeBlikCode': 'Zadejte kód BLIK',
  'blikCodePatternError': 'Kód musí obsahovat 6 číslic',
  'blikCode': 'Kód BLIK',
  'simplifiedInvoice': 'Zjednodušené',
  'fullInvoice': 'Úplné znění',
  'privateInvoice': 'Pojmenované',
  'simplifiedInvoiceInformation': 'Zákazník obdrží účtenku s číslem DPH jako zjednodušenou fakturu. Lze ji vystavit pouze společnostem registrovaným v Polsku. Pro zahraniční společnosti zvolte plnou fakturu.',
  'isNotPolishNip': 'Uvedené číslo NIP není polské.',
  'polishNip': 'Polské DIČ',
  'checkTheBankingApp': 'Zkontrolujte bankovní aplikaci',
  'confirmPaymentWithPin': 'Nyní potvrďte platbu PINem pomocí aplikace ve svém telefonu.',
  'ticketsLoadedAfterConfirm': 'Vstupenky se načtou po potvrzení platby a budou odeslány na e-mail:',
  'paymentWithBlik': 'Platba BLIKem',
  'cannontLoadPage': 'Nepodařilo se načíst tuto stránku',
  'refreshPage': 'Obnovit stránku',
  'isSoldOut': 'Vyprodáno',
  'removeSelectedActivities': 'Odstranění vybraných služeb',
  'removeSelectedServicesQuestion': 'Odstranit vybrané služby?',
  'allServicesWillBeRemoved': 'Všechny služby budou odstraněny a vrátíte se k opětovnému výběru',
  'hourFull': 'Hodina',
  'hasNoSeatsTest': 'Žádná místa',
  'pleaseSelectDate': 'Vyberte prosím datum',
  'pleaseSelectTime': 'Vyberte prosím čas',
  'selected': 'Vybrané stránky',
  'term': 'Termín',
  'price': 'Cena',
  'startDate': 'Datum zahájení',
  'endDate': 'Datum ukončení',
  'daysQuanity': 'Počet dní',
  'minValueError': 'Minimální hodnota je %{value}',
  'maxValueError': 'Maximální hodnota je %{value}'
};

/* eslint-enable max-len */
