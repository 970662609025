import {areActivityDatesInvalid} from '@Utils/activity/activity';
import {getVariantById} from '@Utils/activity/variant';
import {EVENT_BUY_LOADED_ACTIVITY_FROM_LOCAL_STORAGE} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import facebookPixel from '@Utils/facebookPixel';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {EStorageKeys} from '@Consts/storageConsts';
import dayjs from 'dayjs';
import {BASKET_ITEMS_STORAGE_TIME} from '@Consts/timeDurations';
import gtm from '@Utils/gtm/core';
import {findByOnlineGroupId} from '@Utils/utils';
import {setStorage} from '@Features/storage/storageActions';
import {clearBasket} from './basketActions';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';

export const getVariantPrice = (variantId, dates) =>
  dates.map(date => {
    const variantCost = date.variants.find(variantCost => variantCost.variantId === variantId);

    if (!variantCost) {
      return null;
    }

    return {
      ...date,
      price: variantCost.cost.price,
      currency: variantCost.cost.currency
    };
  });

const editVariantDates = (variant, dates, variantId) => {
  if ((variant.id === variantId) && dates.length) {
    return {
      ...variant,
      dates: getVariantPrice(variantId, dates)
    };
  }

  if (!dates.length) {
    return {
      ...variant,
      dates: []
    };
  }

  return {
    ...variant
  };
};

const editAdmissionPolicyDates = admissionDates =>
  admissionDates.map(admissionDate => {
    const {numberOfSeats} = admissionDate;

    return {
      ...admissionDate,
      limit: numberOfSeats,
      used: 0
    };
  });

const updateBasketDates = (basketItem, admissionDates, variantId) => {
  const variantWithDates = editVariantDates(basketItem.variant, admissionDates, variantId);
  const {activity} = basketItem;
  const {admissionPolicy} = activity;

  return {
    ...basketItem,
    variant: variantWithDates,
    activity: {
      ...activity,
      admissionPolicy: {
        ...admissionPolicy,
        dates: admissionDates.length ? editAdmissionPolicyDates(admissionDates) : []
      }
    }
  };
};

export const updateBasket = ({items}, admissionDates, variantId = null) =>
  items.map(basketItem => updateBasketDates(basketItem, admissionDates, variantId));

export const addBasketItemToStorage = ({
  basketItem,
  storageItem,
  storageBasketItems,
  configuration,
  basketItems,
  facility,
  eventType,
  isUpsell
}) => dispatch => {
  const {activityId, orderNumber} = storageItem;
  const {onlineGroupId, affiliationHash} = configuration;

  if (!isUpsell && onlineGroupId) {
    const onlineGroupBasketItemId = storageBasketItems?.findIndex(item => item.onlineGroupId === onlineGroupId);

    if (onlineGroupBasketItemId > -1) {
      storageBasketItems[onlineGroupBasketItemId].timestamp = dayjs().valueOf();
      storageBasketItems[onlineGroupBasketItemId].items.push(storageItem);
    } else {
      storageBasketItems.push({onlineGroupId, items: [storageItem], timestamp: dayjs().valueOf()});
    }

    dispatch(setStorage(EStorageKeys.BASKET_ITEMS, storageBasketItems, true));
  }

  const {activity: {passType}} = basketItem;
  const {id: facilityId, name: facilityName, company} = facility;

  pubsub.trigger(eventType, {
    facilityId,
    facilityName,
    numberOfTickets: basketItems.length,
    industry: company.industry,
    affiliationHash,
    activityId,
    passType,
    orderNumber,
    language: parseLanguage(locale.locale)
  });
  facebookPixel.postAddToCart(facility, basketItem);
  gtm.addToCart(facility, basketItem);
};

const handleBreakInitialize = timestamp => {
  if (!timestamp) {
    return true;
  }

  const deadline = timestamp + BASKET_ITEMS_STORAGE_TIME;
  const isOutdated = dayjs().isAfter(deadline);

  return isOutdated ? true : false;
};

export const initializeBasketFromStorage = ({
  activities,
  onlineGroupId,
  basketItems,
  storageBasketItems,
  onPrepareBasketItem,
  configuration,
  facility
}) => dispatch => {
  const onlineGroupStoredBasket = findByOnlineGroupId(storageBasketItems, onlineGroupId);
  const breakInitialize = handleBreakInitialize(onlineGroupStoredBasket?.timestamp);

  if (breakInitialize) {
    dispatch(clearBasketItemsInStorage(onlineGroupId, storageBasketItems));
    return;
  }

  if (!onlineGroupStoredBasket || !onlineGroupStoredBasket.items || basketItems.length > 0) {
    return;
  }

  for (const item of onlineGroupStoredBasket.items) {
    const {activityId, variantId, uid, orderNumber, selections} = item;

    const activity = activities.find(({id}) => id === activityId);
    const variant = getVariantById(activity?.variants, variantId);

    if (!(activity && variant && !activity.salePolicy.isSoldOut && !areActivityDatesInvalid(activity))) {
      dispatch(clearBasket());

      const storageData = storageBasketItems.map(basketItem => {
        if (basketItem.onlineGroupId === onlineGroupId) {
          return {
            ...basketItem,
            items: []
          };
        }
        return basketItem;
      });

      dispatch(setStorage(EStorageKeys.BASKET_ITEMS, storageData));
      break;
    }

    onPrepareBasketItem({activity, variantId, orderNumber, uid, selections});
  }

  if (onlineGroupStoredBasket.items.length > 0) {
    const {id: facilityId, name: facilityName, company} = facility;
    const {affiliationHash} = configuration;

    pubsub.trigger(EVENT_BUY_LOADED_ACTIVITY_FROM_LOCAL_STORAGE, {
      facilityId,
      facilityName,
      numberOfTickets: onlineGroupStoredBasket.items.length,
      industry: company.industry,
      affiliationHash,
      language: parseLanguage(locale.locale)
    });
  }
};

export const clearBasketItemsInStorage = (onlineGroupId, storageBasketItems) => dispatch => {
  const onlineGroupStoredBasket = findByOnlineGroupId(storageBasketItems, onlineGroupId);

  if (!onlineGroupStoredBasket || onlineGroupStoredBasket.items?.length <= 0) {
    return;
  }

  const storageData = storageBasketItems.map(basketItem => {
    if (basketItem.onlineGroupId === onlineGroupId) {
      return {
        ...basketItem,
        items: [],
        timestamp: 0
      };
    }
    return basketItem;
  });

  dispatch(clearBasket());
  dispatch(setStorage(EStorageKeys.BASKET_ITEMS, storageData, true));
};

export const removeBasketItemFromStorage = (
  activityId, variantId, onlineGroupId
) => (dispatch, getState) => {
  const state = getState();
  const storageBasketItems = getStorageBasketItems(state);
  const onlineGroupStoredBasket = findByOnlineGroupId(storageBasketItems, onlineGroupId);

  if (!onlineGroupStoredBasket || onlineGroupStoredBasket.items.length <= 0) {
    return;
  }
  const onlineGroupStoredBasketItems = onlineGroupStoredBasket.items;

  const toRemoveId = onlineGroupStoredBasketItems.findIndex(item =>
    item.activityId === activityId && item.variantId === variantId
  );

  const filteredOnlineGroupStoredBasketItems = onlineGroupStoredBasketItems.filter((_item, idx) => idx !== toRemoveId);

  const storageData = storageBasketItems?.map(basketItem => {
    if (basketItem.onlineGroupId === onlineGroupId) {
      return {
        ...basketItem,
        items: filteredOnlineGroupStoredBasketItems
      };
    }

    return basketItem;
  });

  dispatch(setStorage(EStorageKeys.BASKET_ITEMS, storageData, true));
};
