import React from 'react';
import locale from '@Utils/locale';
import ButtonWrapper, {ButtonWrapperProps} from './ButtonWrapper';
import Button from '@mui/material/Button/Button';
import {activityPickerButtonStyles} from './styles';

type Props = {
  onClick: () => void;
  isActive: boolean;
} & ButtonWrapperProps;

const ActivityVariantsListToggler = ({onClick, isActive, disableReason}: Props) => (
  <ButtonWrapper disableReason={disableReason}>
    <Button
      onClick={onClick}
      variant={isActive ? 'outlined' : 'contained'}
      sx={{...activityPickerButtonStyles}}
      size="large"
    >
      {locale.translate(isActive ? 'hide' : 'choose')}
    </Button>
  </ButtonWrapper>
);

export default ActivityVariantsListToggler;
