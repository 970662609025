import {CellData} from '@Components/calendar/CalendarCell';
import {HotelNarrowDate} from '@Components/calendar/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {TPreviewTicket} from '@Components/summary/tickets/types';
import {IBusinessOrder, IInternalOrder, IOnlineOrder, IOrder, ITicketOnline} from '@Consts/apiGlobals';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';

export const isOnlineOrder = (
  order?: IOrder
): order is IOnlineOrder => {
  const onlineOrder = order as IOnlineOrder;

  return !!onlineOrder && isTicketOnline(onlineOrder.tickets[0]);
};

export const isTicketOnline = (
  ticket?: ITicketOnline | TPreviewTicket
): ticket is ITicketOnline => {
  const onlineTicket = ticket as ITicketOnline;

  return !!onlineTicket && onlineTicket?.hasOwnProperty('walletPassUrl');
};

export const isBusinessOrder = (
  order?: IOrder
): order is IBusinessOrder => {
  const businessOrder = order as IBusinessOrder;

  return !!businessOrder && !!businessOrder.businessBuyerCompanyId;
};

export const isInternalOrder = (
  order?: IOrder
): order is IInternalOrder => {
  const internalOrder = order as IInternalOrder;

  return !!internalOrder && internalOrder?.hasOwnProperty('paymentViewUrl');
};

export const isDayOffer = (
  cellData: CellData
): cellData is DayOffersProcessed => {
  const dayOffer = cellData as DayOffersProcessed;

  return !!dayOffer?.id;
};

export const isGroupOfDates = (
  cellData: CellData
): cellData is IDatesGroupedByDay => {
  const groupOfDates = cellData as IDatesGroupedByDay;

  return !!groupOfDates?.metaData;
};

export const isHotelNarrowDate = (
  cellData: CellData
): cellData is HotelNarrowDate => {
  const hotelNarrowDate = cellData as HotelNarrowDate;

  return !!hotelNarrowDate?.isHotelNarrowDate;
};

export const isUTCDateString = (
  date: string
): boolean => date.endsWith('+00:00');

export const isInteger = (
  value: number | string
): value is number => Number.isInteger(value);
