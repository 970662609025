import React, {useEffect} from 'react';
import CounterInput from '@Components/counterInput/CounterInput';

import {getInvalidFieldMessage} from '../utils';
import {isInteger} from '@Utils/typeGuards';
import locale from '@Utils/locale';
import {useCounterPicker} from '@Utils/hooks/useCounterPicker';

type Props = {
  minValue: number;
  maxValue: number;
  value: number;
  onBlur: (value: number) => void;
  showErrorAbsolute?: boolean;
  disableReason: string | null;
  basketItemExists: boolean;
  variantId: number;
};

const GroupCounterPicker = ({
  minValue,
  maxValue,
  onBlur,
  value,
  showErrorAbsolute,
  disableReason,
  basketItemExists,
  variantId
}: Props) => {
  const validateValue = (inputValue: number | '') => (
    basketItemExists && isInteger(inputValue) ? inputValue <= maxValue && inputValue >= minValue : true
  );
  const {
    inputValue,
    handleSetInputValue,
    handleblockNextStepWith,
    handleDecrease,
    handleIncrease,
    isValueValid
  } = useCounterPicker({
    validateValue,
    initialValue: value,
    variantId
  });

  const isMaxValueReached = inputValue >= maxValue;
  const disableAddBasketItemReason = !basketItemExists ? disableReason : null;
  const blockAddBasketItem = !value && disableReason;
  const disableIncreaseReason = !disableAddBasketItemReason ?
    isMaxValueReached && locale.translate('canNotBuyMoreTickets') : null;
  const invalidFieldMessage = getInvalidFieldMessage({
    maxValue: disableAddBasketItemReason ? 0 : maxValue,
    minValue,
    inputValue
  });

  const handleInputValueChange = () => {
    if (value !== inputValue) { // Check if this condition is necessary
      if (!isMaxValueReached && !blockAddBasketItem && isInteger(inputValue) && inputValue !== 0) {
        onBlur(inputValue);
      }
    }

    if (value && inputValue === 0) {
      onBlur(0);
      handleSetInputValue(0);
    }
  };

  useEffect(() => {
    handleblockNextStepWith();
    handleInputValueChange();
  }, [inputValue]);

  const handleOnChange = (value: number | '') => {
    handleSetInputValue(value);
  };

  const handleOnBlur = () => {
    // empty field should be changed to 0
    if (!isInteger(inputValue)) {
      onBlur(0);
      handleSetInputValue(0);
    } else if (isValueValid) {
      onBlur(inputValue);
    }
  };

  return (
    <CounterInput
      value={inputValue}
      onDecrease={handleDecrease}
      onIncrease={handleIncrease}
      disableIncreaseReason={disableIncreaseReason}
      invalidFieldMessage={invalidFieldMessage}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      maxValue={maxValue}
      showErrorAbsolute={showErrorAbsolute}
      disableAddBasketItemReason={disableAddBasketItemReason}
    />
  );
};

export default GroupCounterPicker;
