import {ThunkAction} from 'redux-thunk';
import {AnyAction} from 'redux';
import {EWidgetType} from '@Consts/globals';
import {EStorageKeys} from '@Consts/storageConsts';
import {fetchAdditions} from '@Features/activityAdditions/additionsActions';
import {getAdditionsStatus} from '@Features/activityAdditions/additionsSelectors';
import {fetchQuestions} from '@Features/activityQuestions/questionsActions';
import {getQuestionsStatus} from '@Features/activityQuestions/questionsSelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {fetchShippingMethods} from '@Features/shippingMethods/shippingMethodsActions';
import {getShippingMethodsStatus} from '@Features/shippingMethods/shippingMethodsSelectors';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';
import {createBasketItem, getActivityBasketItems, getVariantBasketItems} from '@Utils/basket';
import {EVENT_ADD_TO_BASKET, EVENT_ADD_TO_BASKET_UPSELL} from '@Utils/events';
import {isFailure, isProgress, isSuccess} from '@Utils/status';
import {getBasketItems} from './basketSelectors';
import {addBasketItemToStorage, removeBasketItemFromStorage} from './basketUtils';
import {findByOnlineGroupId} from '@Utils/utils';
import {setStorage} from '@Features/storage/storageActions';
import {IBasketItem, IBasketItemSelections} from '@Consts/types';
import {IStore} from '../../store/types';
import {StorageBasket} from '@Consts/storageTypes';
import {isVariantGroup} from '@Utils/variantType';
import {PrepareBasketData} from './types';
import {getVariantById} from '@Utils/activity/variant';
import {isActivitySingleGroupEvent} from '@Utils/activity/activity';
import gtm from '@Utils/gtm/core';
import {setPickedUpsellActivity} from '@Features/upsell/upsellActions';

export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM';
export const ADD_UPSELL_BASKET_ITEM = 'ADD_UPSELL_BASKET_ITEM';
export const REMOVE_BASKET_ITEM = 'REMOVE_BASKET_ITEM';

export const UPDATE_BASKET_ITEMS_SELECTIONS = 'UPDATE_BASKET_ITEMS_SELECTIONS';
export const APPLY_BASKET_ITEMS_DISCOUNTS = 'APPLY_BASKET_ITEMS_DISCOUNTS';
export const RESET_BASKET_DISCOUNT = 'RESET_BASKET_DISCOUNT';
export const RESET_BASKET_SELECTIONS = 'RESET_BASKET_SELECTIONS';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const UPDATE_BASKET_ITEMS = 'UPDATE_BASKET_ITEMS';
export const UPDATE_BASKET_ITEMS_ADMISSION_DATES = 'UPDATE_BASKET_ITEMS_ADMISSION_DATES';
export const ACTIVITY_ANSWER_SCOPE_ANSWERS = 'ACTIVITY_ANSWER_SCOPE_ANSWERS';
export const BLOCK_NEXT_STEP_WITH = 'BLOCK_NEXT_STEP_WITH';
export const UNBLOCK_NEXT_STEP_WITH = 'UNBLOCK_NEXT_STEP_WITH';

export const addBasketItem = (basketItem: IBasketItem) => ({
  type: ADD_BASKET_ITEM,
  payload: {basketItem}
});

export const prepareBasketItem = (
  basketData: PrepareBasketData,
  isRegenerate = false
): ThunkAction<void, IStore, unknown, AnyAction> => async (dispatch, getState) => {
  const {activity, variantId, uid, selections, isCheckout, orderNumber} = basketData;
  const state = getState();
  const facility = getFacility(state);
  const {id: activityId, additions, questions} = activity;
  const configuration = getConfiguration(state);
  const additionsStatus = getAdditionsStatus(state, activityId);
  const questionsStatus = getQuestionsStatus(state, activityId);
  const extensionsSuccessStatus = isSuccess(additionsStatus) && isSuccess(questionsStatus);
  const extensionsProgressStatus = isProgress(additionsStatus) && isProgress(questionsStatus);
  const shippingMethodsStatus = getShippingMethodsStatus(state);

  const uidValue = uid ? uid :
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  const isUpsell = !!isCheckout;

  if (!extensionsSuccessStatus && !extensionsProgressStatus) {
    if (!additions?.length) {
      dispatch(fetchAdditions(activityId, facility));
    }
    if (!questions?.length) {
      dispatch(fetchQuestions(activityId, facility));
    }
    if (!isSuccess(shippingMethodsStatus) || isFailure(shippingMethodsStatus)) {
      dispatch(fetchShippingMethods());
    }
  }

  const basketItem = createBasketItem(activity, variantId, uidValue, isUpsell);

  if (basketItem) {
    dispatch(addBasketItem({
      ...basketItem,
      selections: selections ? {
        ...basketItem.selections,
        ...selections
      } : {
        ...basketItem.selections
      }
    }));

    if (!isRegenerate) {
      const {selections: {numberOfSeats, numberOfParticipants}} = basketItem;

      const storageBasketItem = {
        uid: uidValue,
        activityId,
        variantId,
        orderNumber,
        selections: {
          numberOfSeats,
          numberOfParticipants
        }
      };
      const eventType = isUpsell ? EVENT_ADD_TO_BASKET_UPSELL : EVENT_ADD_TO_BASKET;

      const storageBasketItems = getStorageBasketItems(state) || [];
      const basketItems = getBasketItems(state);

      dispatch(addBasketItemToStorage({
        basketItem,
        storageItem: storageBasketItem,
        storageBasketItems,
        configuration,
        basketItems,
        facility,
        eventType,
        isUpsell
      }));
    }
  }
};

export const removeBasketItem = (activityId: number, variantId: number) => ({
  type: REMOVE_BASKET_ITEM,
  payload: {activityId, variantId}
});

export const updateBasketItemsSelections = (
  basketItems: IBasketItem[],
  selections: Partial<IBasketItemSelections>
) => ({
  type: UPDATE_BASKET_ITEMS_SELECTIONS,
  payload: {basketItems, selections}
});

export const prepareBasketItemsSelections = (
  basketItems: IBasketItem[],
  selections: Partial<IBasketItemSelections>
): ThunkAction<void, IStore, unknown, AnyAction> => async (dispatch, getState) => {
  const state = getState();
  const {onlineGroupId, widgetType} = getConfiguration(state);

  if (widgetType === EWidgetType.ONLINE_CLIENT && (selections.numberOfSeats || selections.numberOfParticipants)) {
    const {activityId, variantId} = basketItems?.[0];
    const storageBasketItems: StorageBasket[] = getStorageBasketItems(state) || [];
    const onlineGroupStoredBasket: StorageBasket = findByOnlineGroupId(storageBasketItems, onlineGroupId);

    if (!onlineGroupStoredBasket || onlineGroupStoredBasket.items.length <= 0) {
      return;
    }

    const changedOnlineGroupStoredBasketItems =
      onlineGroupStoredBasket.items.map(item => {
        if (item.activityId === activityId && item.variantId === variantId) {
          return {
            ...item,
            selections: {
              ...item.selections,
              numberOfSeats: selections?.numberOfSeats ?? item.selections.numberOfSeats,
              numberOfParticipants: selections?.numberOfParticipants ?? item.selections.numberOfParticipants
            }
          };
        }

        return item;
      });

    const storageData = storageBasketItems?.map(basketItem => {
      if (basketItem?.onlineGroupId === onlineGroupId) {
        return {
          ...basketItem,
          items: changedOnlineGroupStoredBasketItems
        };
      }

      return basketItem;
    });

    dispatch(setStorage(EStorageKeys.BASKET_ITEMS, storageData, true));
  }

  dispatch(updateBasketItemsSelections(basketItems, selections));
};

export const modifyBasket = (
  basketData: PrepareBasketData,
  action: 'add' | 'remove'
): ThunkAction<void, IStore, unknown, AnyAction> => async (dispatch, getState) => {
  const state = getState();
  const {onlineGroupId} = getConfiguration(state);
  const basketItems = getBasketItems(state);
  const facility = getFacility(state)!;
  const {
    activity,
    variantId,
    isCheckout,
    isUpsellActivity
  } = basketData;
  const {passType, variants, id: activityId} = activity;
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const variantBasketItems = getVariantBasketItems(activityBasketItems, activityId, variantId);
  const variant = getVariantById(variants, variantId);
  const isGroupVariantType = isVariantGroup(variant?.type);
  const isActivityGroupEvent = isActivitySingleGroupEvent(passType);
  const sampleVariantBasketItem = variantBasketItems.length ? variantBasketItems[0] : null;
  const modifier = action === 'add' ? 1 : -1;

  if (action === 'add' && isUpsellActivity) {
    dispatch(setPickedUpsellActivity(activity));
  }

  // TODO:: Remove basket item when number of participants or number reach 0
  if (isGroupVariantType && sampleVariantBasketItem) {
    const numberOfSeats = sampleVariantBasketItem.selections.numberOfSeats + modifier;

    if (numberOfSeats > 0) {
      return dispatch(prepareBasketItemsSelections(
        [sampleVariantBasketItem],
        {numberOfSeats}
      ));
    }
  }

  if (isActivityGroupEvent && sampleVariantBasketItem) {
    const numberOfParticipants = sampleVariantBasketItem.selections.numberOfParticipants + modifier;

    if (numberOfParticipants > 0) {
      return dispatch(prepareBasketItemsSelections(
        [sampleVariantBasketItem],
        {numberOfParticipants}
      ));
    }
  }

  if (action === 'add') {
    return dispatch(prepareBasketItem(basketData, isCheckout));
  }

  if (action === 'remove') {
    dispatch(removeBasketItemFromStorage(activityId, variantId, onlineGroupId));

    gtm.removeFromCart(facility, sampleVariantBasketItem);

    return dispatch(removeBasketItem(activityId, variantId));
  }
};

export const updateBasketItems = (basketItems: IBasketItem[]) => ({
  type: UPDATE_BASKET_ITEMS,
  payload: {basketItems}
});

export const applyBasketItemsDiscounts = (discounts: unknown) => ({
  type: APPLY_BASKET_ITEMS_DISCOUNTS,
  payload: {discounts}
});

export const resetBasketSelections = () => ({
  type: RESET_BASKET_SELECTIONS
});

export const resetBasketDiscount = () => ({
  type: RESET_BASKET_DISCOUNT
});

export const clearBasket = () => ({
  type: CLEAR_BASKET
});

export const blockNextStepWith = (variantId: number) => ({
  type: BLOCK_NEXT_STEP_WITH,
  payload: {variantId}
});

export const unblockNextStepWith = (variantId: number) => ({
  type: UNBLOCK_NEXT_STEP_WITH,
  payload: {variantId}
});
