import {getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {blockNextStepWith, unblockNextStepWith} from '@Features/basket/basketActions';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {isInteger} from '@Utils/typeGuards';

type Props = {
  validateValue: (inputValue: number | '') => boolean;
  initialValue: number;
  variantId: number;
};

export const useCounterPicker = ({validateValue, initialValue, variantId}: Props) => {
  const [inputValue, setInputValue] = useState<number | ''>(initialValue);
  const dispatch = useDispatch();
  const isblockNextStepWith = useSelector(getIsNextStepBlocked);
  const isValueValid = validateValue(inputValue);

  const handleblockNextStepWith = () => {
    if (!isValueValid) {
      dispatch(blockNextStepWith(variantId));
    }

    if (isblockNextStepWith && validateValue(inputValue)) {
      dispatch(unblockNextStepWith(variantId));
    }
  };

  const handleSetInputValue = (value: number | '') => {
    setInputValue(value);
  };

  const handleDecrease = () => {
    const decresedValue = isInteger(inputValue) ? inputValue - 1 : 0;

    handleSetInputValue(decresedValue);
  };

  const handleIncrease = () => {
    const increasedValue = isInteger(inputValue) ? inputValue + 1 : 1;

    handleSetInputValue(increasedValue);
  };

  return {
    inputValue,
    handleSetInputValue,
    handleblockNextStepWith,
    handleDecrease,
    handleIncrease,
    isValueValid: validateValue(inputValue)
  };
};
