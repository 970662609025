import React from 'react';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {isInteger} from '@Utils/typeGuards';
import {Box} from '@mui/system';
import FormHelperText from '@mui/material/FormHelperText';

const increaseAriaLabel = 'increase';
const decreaseAriaLabel = 'decrease';

type Props = {
  minValue?: number;
  maxValue?: number;
  onDecrease: () => void;
  onIncrease: () => void;
  disableIncreaseReason: string;
  value: number | '';
  onChange: (value: number | '') => void;
  onBlur: (value: number) => void;
  invalidFieldMessage?: string | null;
  maxInputLength?: number;
  showErrorAbsolute?: boolean;
  disableAddBasketItemReason?: string | null;
}

function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
  if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === '+') {
    e.preventDefault();
  }
}

const getButtonStyles = {
  borderRadius: '50%',
  minWidth: 44,
  minHeight: 44,
  p: 0,
  my: 0
};

const absoluteErrorPositionStyles = {
  position: 'absolute',
  top: '44px',
  mt: 0,
  mr: 0.5
};

const CounterInput = ({
  minValue = 0,
  maxValue = 1000000,
  onDecrease,
  onIncrease,
  onChange,
  onBlur,
  value,
  maxInputLength = 3,
  disableIncreaseReason,
  invalidFieldMessage,
  showErrorAbsolute,
  disableAddBasketItemReason
}: Props) => {
  const handleDecrease = () => {
    onDecrease();
  };

  const handleIncrease = () => {
    onIncrease();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);

    if (isNaN(value)) {
      return onChange('');
    }

    if (isInteger(value) && value.toString().length <= maxInputLength) {
      onChange(value);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    const relatedTargetName = event.relatedTarget?.ariaLabel;

    // prevent onBlur event when clicking on increase or decrease button
    if (relatedTargetName !== increaseAriaLabel && relatedTargetName !== decreaseAriaLabel) {
      onBlur(value);
    }
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'end', position: 'relative'}}>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content'
        }}
      >
        <Button
          variant="contained"
          aria-label={decreaseAriaLabel}
          size="small"
          color="primary"
          onClick={handleDecrease}
          disabled={isInteger(value) ? value <= minValue : false}
          sx={getButtonStyles}
        >
          <RemoveOutlinedIcon />
        </Button>
        <TextField
          onKeyDown={handleKeyDown}
          sx={{mx: 1, width: 54}}
          size="small"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          value={value}
          error={!!invalidFieldMessage}
          inputProps={{
            'aria-label': 'counterValue',
            min: minValue,
            max: maxValue,
            step: 'any',
            style: {textAlign: 'center'}
          }}
        />
        <Tooltip
          title={disableAddBasketItemReason || disableIncreaseReason}
          enterTouchDelay={50}
          arrow
        >
          <span>
            <Button
              variant="contained"
              aria-label={increaseAriaLabel}
              size="small"
              color="primary"
              onClick={handleIncrease}
              disabled={!!disableAddBasketItemReason || !!disableIncreaseReason}
              sx={getButtonStyles}
            >
              <AddOutlinedIcon />
            </Button>
          </span>
        </Tooltip>
      </Stack>
      {
        invalidFieldMessage &&
        <FormHelperText
          sx={{...showErrorAbsolute ? absoluteErrorPositionStyles : {mr: 0.5}}}
          error
        >
          {invalidFieldMessage}
        </FormHelperText>
      }
    </Box>
  );
};

export default CounterInput;
