import React, {createContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ABTest, ABTestContext} from './types';
import {getAbTestType} from '@Features/storage/storageSelectors';
import {setStorage} from '@Features/storage/storageActions';
import {EStorageKeys} from '@Consts/storageConsts';

const AB_TEST_PROBABILITY = 0.5;

export const AbTestContext = createContext<ABTestContext>({variant: null});

export const AbTestProvider = ({children}: React.PropsWithChildren<{}>) => {
  const dispatch = useDispatch();
  const currentAbTestType = useSelector(getAbTestType);

  const saveToStorage = (variant: ABTest) => {
    const timestamp = Date.now();

    dispatch(setStorage(EStorageKeys.AB_TEST, variant, true));
    dispatch(setStorage(EStorageKeys.AB_TEST_TIMESTAMP, timestamp, true));
  };

  const getAbTest = () => {
    const isCurrentAbTestValid = currentAbTestType === ABTest.TEST_A || currentAbTestType === ABTest.TEST_B;

    if (isCurrentAbTestValid) {
      return currentAbTestType;
    }

    const abTest = Math.random() < AB_TEST_PROBABILITY ? ABTest.TEST_A : ABTest.TEST_B;

    saveToStorage(abTest);

    return abTest;
  };

  return (
    <AbTestContext.Provider
      value={{
        variant: getAbTest()
      }}
    >
      {children}
    </AbTestContext.Provider>
  );
};
