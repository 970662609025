import {getAbTestType} from '@Features/storage/storageSelectors';
import {useSelector} from 'react-redux';
import {ABTest} from '@Contexts/abTestProvider/types';

export enum Features {
  NEW_CALENDAR = 'newCalendar',
}

const useFeatureFlag = (feature: Features) => {
  const currentAbTestType = useSelector(getAbTestType);

  if (feature === Features.NEW_CALENDAR && currentAbTestType === ABTest.TEST_B) {
    return {isEnabled: true};
  }

  return {isEnabled: false};
};

export default useFeatureFlag;
